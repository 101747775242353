class Select2Filter {
  constructor() {
    this._term_full = null;
    this._rgx_match = null;
    this._rgx_sort = null;
    this._rgx_exact_start = null;

    this.matcher = this.matcher.bind(this);
    this.sorter = this.sorter.bind(this);
  }

  matcher(params, data) {
    const oldTermFull = this._term_full;
    this._term_full = MFR
      .stripDiacritics($.trim(params.term) || '')
      .toLowerCase()
      .replace(Select2Filter.TERM_IGNORE, '');
    if (!Select2Filter.PROCESSED_TEXTS.hasOwnProperty(data.text)) {
      // populate dictionary
      Select2Filter.PROCESSED_TEXTS[data.text] = MFR
        .stripDiacritics(data.text.toLowerCase())
        .replace(Select2Filter.TEXT_IGNORE_EMPTY, '')
        .replace(Select2Filter.TEXT_IGNORE_SPACE, ' ');
    }
    // We cache the work of converting the term to a regex
    // so it's done once per term instead of once per food text.
    if (oldTermFull !== this._term_full) {
      // UNORDERED WORDS:
      //   ensure all term words exist in text, but order is unimportant.
      //   example AND: 'a b b' => /^(?=.*a)(?=.*b.*b)/
      //   example OR : 'a b b' => /(?:^a)|(?:^b)|(?:a)|(?:b)/
      // ORDERED CHARACTERS WITHIN WORDS:
      //   allow any number of text characters between term characters.
      //   note that inverted charclass is MUCH MUCH faster than '.*?'
      //   example: 'wow' => /w[^o]*o[^w]*w/
      const terms = this._term_full.split(Select2Filter.TERMS_DELIMITER);
      const termInfo = {};
      for (let i = 0; i < terms.length; ++i) {
        const term = terms[i];
        if (termInfo.hasOwnProperty(term)) {
          termInfo[term].count += 1;
        } else {
          const termChars = Array.from(term);
          const rgxSparseStringBuild = [];
          for (let i = 0; i < termChars.length; ++i) {
            const c0 = termChars[i];
            const e0 = Select2Filter.RGX_RESERVED_MAIN.test(c0) ? '\\' : '';
            rgxSparseStringBuild.push(`${e0}${c0}`);
            if (i !== termChars.length - 1) {
              const c1 = termChars[i+1];
              const e1 = Select2Filter.RGX_RESERVED_CC.test(c1) ? '\\' : '';
              rgxSparseStringBuild.push(`[^${e1}${c1}]*`);
            }
          }
          termInfo[term] = {};
          termInfo[term].count = 1;
          termInfo[term].rgxSparseString = rgxSparseStringBuild.join('');
          termInfo[term].rgxExactString = term.replace(Select2Filter.RGX_RESERVED_MAIN, '\\$&');
        }
      }
      const termInfoEntries = Object.entries(termInfo);
      { // create AND regex for matching
        const dst = ['^'];
        for (let [term, info] of termInfoEntries) {
          dst.push(`(?=`);
          const termWord = `.*${info.rgxSparseString}`;
          for (let i = 0; i < info.count; ++i) {
            dst.push(termWord);
          }
          dst.push(`)`);
        }
        this._rgx_match = RegExp(dst.join(''), '');
      }
      { // create OR regex for sorting
        // ORDER (preferring multiple exact matches)
        //   1. multiple exact matches
        //   2. exact match at start
        //   3. exact match not at start
        //   4. sparse match at start
        //   5. anything that matched the AND regex
        const dst_multiexact = [];
        const dst_exactstart = [];
        const dst_exact = [];
        const dst_sparse = [];
        const combinations = Select2Filter.sortedMultiCombo(terms);
        for (let i = 0; i < combinations.length; ++i) { // multi exact
          const multiTermGroup = combinations[i];
          const subset = [];
          for (let j = 0; j < multiTermGroup.length; ++j) {
            // make sure each term is present
            subset.push(`(?=.*${termInfo[multiTermGroup[j]].rgxExactString})`);
          }
          for (let j = 0; j < multiTermGroup.length; ++j) {
            subset.push('.'); // this lets us count number of exact matches
          }
          dst_multiexact.push(subset.join(''));
        }
        for (let [term, info] of termInfoEntries) { // exact start
          dst_exactstart.push(info.rgxExactString);
        }
        for (let [term, info] of termInfoEntries) { // exact
          dst_exact.push(`(?:${info.rgxExactString})`);
        }
        for (let [term, info] of termInfoEntries) { // start
          dst_sparse.push(`(?:^${info.rgxSparseString})`);
        }
        const sortBuilder = [];
        if (dst_multiexact.length > 0) {
          sortBuilder.push(`(^(?:${dst_multiexact.join('|')}))`);
        } else {
          sortBuilder.push(`($)^`); // keep capture groups balanced
        }
        sortBuilder.push(`(${dst_exact.join('|')})`);
        sortBuilder.push(`${dst_sparse.join('|')}`);
        this._rgx_sort = RegExp(sortBuilder.join('|'));
        this._rgx_exact_start = RegExp(`^(?:${dst_exactstart.join('|')})`);
      }
    }
    if (this._term_full.length === 0) {
      return data;
    } else if (data.disabled) {
      return null;
    }
    const text = Select2Filter.PROCESSED_TEXTS[data.text];
    return this._rgx_match.test(text) ? data : null;
  }

  sorter(data) {
    const self = this;
    return data.sort(function(a, b) {
      const params = [a, b];
      if (self._term_full.length === 0) {
        // some items sort differently when there is no filter
        const forcedOrders = params.map(x => parseInt(x.element.dataset.forceSort) || 0);
        const forcedOrder = forcedOrders[0] - forcedOrders[1];
        if (forcedOrder !== 0) { return forcedOrder; }
      }
      const cleanTexts = params.map(x => Select2Filter.PROCESSED_TEXTS[x.text]);
      if (self._term_full.length > 0) {
        const matches = cleanTexts.map(x => x.match(self._rgx_sort));
        const scores = [0, 0];
        if ((matches[0] == null) != (matches[1] == null)) {
          // only a or b matched, so no special scoring necessary
          return matches[0] ? -1 : 1;
        } else if  (matches[0] && matches[1]) {
          // both matched, so apply scoring. Multiplers:
          const multExact = 10;       // 1) matched exactly
          const multStart = 1;        // 2) matched at start
          for (let i = 0; i < 2; ++i) {
            if (matches[i][1] != null) { // multi-exact
              const num = matches[i][1].length // see `subset.push('.')` above
              scores[i] += multExact * Math.min(9, num);
              if (self._rgx_exact_start.test(cleanTexts[i])) {
                scores[i] += multStart;
              }
            } else {
              if (matches[i].index === 0) {
                scores[i] += multStart;
              }
              if (matches[i][2] != null) {
                scores[i] += multExact;
              }
            }
          }
          const scoreDiff = scores[1] - scores[0];
          if (scoreDiff !== 0) {
            return scoreDiff;
          }
        } // else neither matched so no special scoring
      }
      return cleanTexts[0].localeCompare(cleanTexts[1]);
    });
  }
}
Select2Filter.PROCESSED_TEXTS = {}; // dictionary of processed text
Select2Filter.RGX_RESERVED_MAIN = /[\[\\.|?*+()^$]/g; // escape in main part of regex
Select2Filter.RGX_RESERVED_CC = /[\^\-\]\\]/g; // escape in character class
Select2Filter.TERM_IGNORE = /[,'"]+/gm; // what to ignore in the ordered search term
Select2Filter.TERMS_DELIMITER = /[-\s]+/; // what to ignore in the ordered search term
Select2Filter.TEXT_IGNORE_EMPTY = /[,'"]+/gm; // what to change to '' in the data text
Select2Filter.TEXT_IGNORE_SPACE = /[-\s]+/gm; // what to change to ' ' in the data text
Select2Filter.sortedMultiCombo = function(array) {
  // We get the "power set" of the array (except for lengths of 1 or 0)
  const result = [];
  result.push();
  for (let i = 1; i < (1 << array.length); ++i) {
    const subset = [];
    for (let j = 0; j < array.length; ++j) {
      if (i & (1 << j)) {
        subset.push(array[j]);
      }
    }
    if (subset.length > 1) {
      result.push(subset);
    }
  }
  return result.sort((a, b) => b.length - a.length);
}
