import React from "react";
import PropTypes from "prop-types";

import DishFoodMediaSegmentor_DrawButtons from "./draw_buttons.es6.jsx"
import DishFoodMediaSegmentor_DishFoodSegmentList from "./dish_food_segment_list.es6.jsx"
import DishFoodMediaSegmentor_DishFoodFields from "./dish_food_fields.es6.jsx"
import DishFoodMediaSegmentor_MediaSelector from "./media_selector.es6.jsx"

class DishFoodMediaSegmentor extends React.Component {
  constructor(props) {
    super(props);
    this.onBrowserNav = this.onBrowserNav.bind(this);
    this.onDrawButtonClick = this.onDrawButtonClick.bind(this);
    this.onDishFoodAndSegmentSelectionChanged = this.onDishFoodAndSegmentSelectionChanged.bind(this);
    this.onDishFoodAdded = this.onDishFoodAdded.bind(this);
    this.onSelectedMediaIdxChanged = this.onSelectedMediaIdxChanged.bind(this);
    this.onDishFoodFoodChanged = this.onDishFoodFoodChanged.bind(this);
    this.onDishFoodAmtChanged = this.onDishFoodAmtChanged.bind(this);
    this.onDishFoodDeleted = this.onDishFoodDeleted.bind(this);
    this.onSegmentAdded = this.onSegmentAdded.bind(this);
    this.onSegmentRedraw = this.onSegmentRedraw.bind(this);
    this.onSegmentDeleted = this.onSegmentDeleted.bind(this);
    this.onSelectedSegmentShapeFinalized = this.onSelectedSegmentShapeFinalized.bind(this);
    this.onDrawModeCanceled = this.onDrawModeCanceled.bind(this);

    const dishFoods = JSON.parse(JSON.stringify(props.dishFoods)); // quick-and-dirty deep copy
    const mediaPresent = this.props.media.length !== 0;
    this.nextColorIdx = 0;
    this.nextComponentGuid = 0;
    for (const dishFood of dishFoods) {
      dishFood.componentGuid = this.nextComponentGuid++;
      dishFood.persisted = true;
      dishFood.presentUnit = dishFood.presentUnit || dishFood.foodUnit || 'g';
      if (dishFood.eatenQuantity == null && dishFood.eatenUnit == null) {
        if (mediaPresent) {
          dishFood.eatenQuantity = 100;
          dishFood.eatenUnit = '%';
        } else {
          dishFood.eatenQuantity = dishFood.presentQuantity;
          dishFood.eatenUnit = dishFood.presentUnit;
        }
      } else if (!mediaPresent && dishFood.eatenUnit === '%') {
        dishFood.eatenUnit = dishFood.presentUnit;
        try {
          dishFood.eatenQuantity = parseInt(dishFood.presentQuantity) * parseInt(dishFood.eatenQuantity) * 0.01;
          if (isNaN(dishFood.eatenQuantity)) {
            dishFood.eatenQuantity = '';
          }
        } catch (err) {
          dishFood.eatenQuantity = '';
        }
      }
      this.applyFoodStandardPortion(dishFood, false);
      for (const segment of dishFood.segments) {
        segment.componentGuid = this.nextComponentGuid++;
        segment.persisted = true;
        segment.colorIdx = this.nextColorIdx++;
      }
    }
    const sDFI = dishFoods.length === 1 ? 0 : -1;
    const sDFSI = dishFoods[sDFI] && dishFoods[sDFI].segments.length === 1 ? 0 : -1;
    const sDFS = sDFSI === -1 ? null : dishFoods[sDFI].segments[sDFSI];
    const sMI = this.props.media.length === 0 ? -1 : sDFS == null ? 0 : this.props.media.findIndex(x => x.id === sDFS.mediaId);
    this.state = {
      mode: 'normal',
      dishFoods: dishFoods,
      selectedDishFoodIdx: sDFI,
      selectedDishFoodSegmentIdx: sDFSI,
      selectedMediaIdx: sMI,
      deletedDishFoods: [],
      deletedSegments: [],
      numPersistedDeletions: 0,
    }
  }

  componentDidMount() {
    $(window).on('turbolinks:before-visit popstate', this.onBrowserNav);

    const $form = $('#edit_dish_form');

    $form.on("ajax:beforeSend", () => {
      if (this.state.mode === 'draw_shape') {
        alert('You are still drawing segments! Please finish or cancel to prevent losing work.');
        $.rails.enableElement($form[0]);
        return false;
      }
    });

    $form.on("ajax:error", event => {
      const [_data, _status, xhr] = event.detail;
      try {
        alert(JSON.parse(xhr.responseText).join('\n'));
      } catch(e) {
        alert(`Unknown error, please check the form entries.\n\nStatus: ${xhr.statusText} (${xhr.status})`);
      }
    });
  }

  componentWillUnmount() {
    $(window).off('turbolinks:before-visit popstate', this.onBrowserNav);
  }

  render() {
    const selectedMediaId = this.state.selectedMediaIdx === -1
                            ? null
                            : this.props.media[this.state.selectedMediaIdx].id;
    let deletedSegmentDishFoodIndexAccum = 0;
    return (
      <div>
        <DishFoodMediaSegmentor_DrawButtons
          mode={this.state.mode}
          onDrawButtonClick={this.onDrawButtonClick}
        />
        <div className="segmentation">
          <DishFoodMediaSegmentor_DishFoodSegmentList
            media={this.props.media}
            selectedMediaIdx={this.state.selectedMediaIdx}
            dishFoods={this.state.dishFoods}
            selectedDishFoodIdx={this.state.selectedDishFoodIdx}
            selectedDishFoodSegmentIdx={this.state.selectedDishFoodSegmentIdx}
            colorFromIndex={DishFoodMediaSegmentor.colorFromIndex}
            onDishFoodAndSegmentSelectionChanged={this.onDishFoodAndSegmentSelectionChanged}
            onDishFoodAdded={this.onDishFoodAdded}
          />
          <DishFoodMediaSegmentor_MediaSelector
            ref="mediaSelectorComponent"
            mode={this.state.mode}
            ddsgId={this.props.ddsgId}
            media={this.props.media}
            selectedMediaIdx={this.state.selectedMediaIdx}
            onSelectedMediaIdxChanged={this.onSelectedMediaIdxChanged}
            dishFoods={this.state.dishFoods}
            selectedDishFoodIdx={this.state.selectedDishFoodIdx}
            selectedDishFoodSegmentIdx={this.state.selectedDishFoodSegmentIdx}
            colorFromIndex={DishFoodMediaSegmentor.colorFromIndex}
            onSelectedSegmentShapeFinalized={this.onSelectedSegmentShapeFinalized}
            onDrawModeCanceled={this.onDrawModeCanceled}
          />
          <DishFoodMediaSegmentor_DishFoodFields
            ddsgId={this.props.ddsgId}
            dishFoods={this.state.dishFoods}
            selectedDishFoodIdx={this.state.selectedDishFoodIdx}
            selectedDishFoodSegmentIdx={this.state.selectedDishFoodSegmentIdx}
            selectedMediaId={selectedMediaId}
            onDishFoodFoodChanged={this.onDishFoodFoodChanged}
            onDishFoodAmtChanged={this.onDishFoodAmtChanged}
            onDishFoodDeleted={this.onDishFoodDeleted}
            onSegmentAdded={this.onSegmentAdded}
            onSegmentRedraw={this.onSegmentRedraw}
            onSegmentDeleted={this.onSegmentDeleted}
          />
        </div>
        <div data-context="deletions">
          {this.state.deletedDishFoods.map((dishFood, idx) => {
            const dishFoodIdx = this.state.dishFoods.length + idx;
            return (
              <React.Fragment key={`deleted-dishfood-${this.state.numShifts}-${dishFood.id}`}>
                <input
                  type="hidden"
                  name={`ddsg[dish_foods_attributes][${dishFoodIdx}][id]`}
                  value={dishFood.id}
                />
                <input
                  type="hidden"
                  name={`ddsg[dish_foods_attributes][${dishFoodIdx}][_destroy]`}
                  value="1"
                />
              </React.Fragment>
            );
          })}
          {this.state.deletedSegments.map((segment, idx) => {
            let dishFoodIdx = this.state.dishFoods.findIndex(x => x.id === segment.dishFoodId);
            let dishFoodIdRequired = false;
            if (dishFoodIdx === -1) {
              dishFoodIdx = this.state.deletedDishFoods.findIndex(x => x.id === segment.dishFoodId);
              if (dishFoodIdx === -1) {
                // this should NEVER happen!
                dishFoodIdRequired = true;
                dishFoodIdx = this.state.deletedDishFoods.length + deletedSegmentDishFoodIndexAccum++;
              }
              dishFoodIdx += this.state.dishFoods.length
            }
            const segmentIdx = this.state.dishFoods.length + idx;
            return (
              <React.Fragment key={`deleted-segment-${this.state.numShifts}-${segment.id}`}>
                {dishFoodIdRequired ? <input
                  type="hidden"
                  name={`ddsg[dish_foods_attributes][${dishFoodIdx}][id]`}
                  value={segment.dishFoodId}
                /> : null}
                <input
                  type="hidden"
                  name={`ddsg[dish_foods_attributes][${dishFoodIdx}][segments_attributes][${segmentIdx}][id]`}
                  value={segment.id}
                />
                <input
                  type="hidden"
                  name={`ddsg[dish_foods_attributes][${dishFoodIdx}][segments_attributes][${segmentIdx}][_destroy]`}
                  value="1"
                />
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  }

  newSegment() {
    return {
      componentGuid: this.nextComponentGuid++,
      mediaId: this.props.media[this.state.selectedMediaIdx].id,
      location: { polygons: [] },
      colorIdx: this.nextColorIdx++,
    };
  }

  onBrowserNav(evt) {
    if (this.state.mode === 'draw_shape') {
      alert('You were drawing segments. Navigating away will lose any work done on this page.');
    }
  }

  onDrawButtonClick(action) {
    this.refs.mediaSelectorComponent.onShapeAction(action);
  }

  onDishFoodAndSegmentSelectionChanged(dishFoodIdx, dfSegmentIdx) {
    if (dishFoodIdx === this.state.selectedDishFoodIdx && dfSegmentIdx === this.state.selectedDishFoodSegmentIdx) {
      return;
    }
    if (this.state.mode === 'draw_shape') {
      alert('Cannot change dishfood selection while drawing. Please finish drawing the shape that represents the current segment.');
      return;
    }
    let newSelectedMediaIdx = this.state.selectedMediaIdx;
    if (dishFoodIdx !== -1 && dfSegmentIdx !== -1) {
      const segment = this.state.dishFoods[dishFoodIdx].segments[dfSegmentIdx];
      newSelectedMediaIdx = this.props.media.findIndex(x => x.id === segment.mediaId);
    }
    this.setState({
      selectedDishFoodIdx: dishFoodIdx,
      selectedDishFoodSegmentIdx: dfSegmentIdx,
      selectedMediaIdx: newSelectedMediaIdx,
    });
  }

  onDishFoodAdded() {
    if (this.state.mode === 'draw_shape') {
      alert('Cannot Add a new dishfood while drawing. Please finish drawing the shape that represents the current segment.');
      return;
    }
    let newMode = this.state.mode;
    const mediaPresent = this.props.media.length !== 0;
    const newDishFoods = this.state.dishFoods.slice();
    const newDishFood = {
      componentGuid: this.nextComponentGuid++,
      foodDisplayNameHtml: '???',
      foodId: null,
      persisted: false,
      delete: false,
      presentUnit: 'g',
      eatenQuantity: mediaPresent ? 100 : '',
      eatenUnit:     mediaPresent ? '%' : 'g',
      withFoodEnergyLimitsValidator: '1',
      segments: [],
    };
    if (this.state.selectedMediaIdx >= 0) {
      newMode = 'draw_shape';
      newDishFood.segments.push(this.newSegment());
    }
    newDishFood.calculatedEatenGrams = this.calculateEatenGrams(newDishFood);
    newDishFood.calculatedEatenMililiters = this.calculateEatenMililiters(newDishFood);
    newDishFoods.push(newDishFood);
    this.setState({
      mode: newMode,
      dishFoods: newDishFoods,
      selectedDishFoodIdx: newDishFoods.length - 1,
      selectedDishFoodSegmentIdx: newDishFood.segments.length - 1,
      numShifts: this.state.numShifts + 1,
    });
  }

  onSelectedMediaIdxChanged(newIdx) {
    if (newIdx < 0 || newIdx > this.props.media.length - 1) {
      return;
    }
    if (this.state.mode === 'draw_shape') {
      alert('Cannot change media while drawing. Please finish drawing the shape that represents the current segment.');
      return;
    }
    let newDFSI = -1;
    if (this.state.selectedDishFoodIdx !== -1) {
      const newMediaId = this.props.media[newIdx].id;
      const dishFood = this.state.dishFoods[this.state.selectedDishFoodIdx];
      newDFSI = dishFood.segments.findIndex(x => x.mediaId === newMediaId);
    }
    this.setState({
      selectedMediaIdx: newIdx,
      selectedDishFoodSegmentIdx: newDFSI,
    });
  }

  onDishFoodFoodChanged(dishFoodIdx, dfChanges, clearPresentQuantity = false) {
    const newDishFoods = this.state.dishFoods.slice();
    const newDishFood = Object.assign({}, newDishFoods[dishFoodIdx], dfChanges);
    // we're changing food, so segment machine learning was incorrect
    if (newDishFood.segments.reduce((acc, x) => acc || (x.mlConfidence != null && x.mlCorrect), false)) {
      const newSegments = newDishFood.segments.slice();
      for (let segmentIdx = 0; segmentIdx < newSegments.length; ++segmentIdx) {
        if (newSegments[segmentIdx].mlConfidence != null && newSegments[segmentIdx].mlCorrect) {
          const newSegment = Object.assign({}, newSegments[segmentIdx], { mlCorrect: false });
          newSegments[segmentIdx] = newSegment;
        }
      }
      newDishFood.segments = newSegments;
    }
    if (!clearPresentQuantity) {
      this.applyFoodStandardPortion(newDishFood, true);
    }
    newDishFood.calculatedEatenGrams = this.calculateEatenGrams(newDishFood);
    newDishFood.calculatedEatenMililiters = this.calculateEatenMililiters(newDishFood);
    newDishFoods[dishFoodIdx] = newDishFood;
    this.setState({
      dishFoods: newDishFoods,
    });
  }

  onDishFoodAmtChanged(dishFoodIdx, changes) {
    const newDishFoods = this.state.dishFoods.slice();
    const newDishFood = Object.assign({}, newDishFoods[dishFoodIdx], changes);

    newDishFood.calculatedEatenGrams = this.calculateEatenGrams(newDishFood);
    newDishFood.calculatedEatenMililiters = this.calculateEatenMililiters(newDishFood);
    newDishFoods[dishFoodIdx] = newDishFood;
    this.setState({
      dishFoods: newDishFoods,
    });
  }

  onDishFoodDeleted(dishFoodIdx) {
    const dishFood = this.state.dishFoods[dishFoodIdx];
    if (!dishFood) {
      alert(`Cannot delete invalid dishfood index: ${dishFoodIdx}`);
    } else {
      const newDishFoods = this.state.dishFoods.slice();
      let deletedDishFoods = this.state.deletedDishFoods;
      let deletedSegments = this.state.deletedSegments;
      newDishFoods.splice(dishFoodIdx, 1);
      if (dishFood.persisted) {
        deletedDishFoods = deletedDishFoods.slice();
        deletedDishFoods.push({
          id: dishFood.id,
        });

        let numPersistedSegments = 0;
        dishFood.segments.forEach(segment => {
          if (segment.persisted) {
            if (0 === numPersistedSegments++) {
              deletedSegments = deletedSegments.slice();
            }
            deletedSegments.push({
              id: segment.id,
              dishFoodId: dishFood.id,
            });
          }
        });
      }
      if (this.state.mode === 'draw_shape') {
        this.refs.mediaSelectorComponent.clearDrawingData();
      }
      let newSelectedDishFoodIdx = dishFoodIdx;
      if (newSelectedDishFoodIdx >= newDishFoods.length) {
        newSelectedDishFoodIdx = newDishFoods.length - 1;
      }
      let newSelectedDishFoodSegmentIdx = -1;
      if (newSelectedDishFoodIdx !== -1) {
        const newDFS = newDishFoods[newSelectedDishFoodIdx].segments;
        if (newDFS.length > 0) {
          newSelectedDishFoodSegmentIdx = 0;
        }
      }
      this.setState({
        mode: 'normal',
        dishFoods: newDishFoods,
        deletedDishFoods: deletedDishFoods,
        deletedSegments: deletedSegments,
        selectedDishFoodIdx: newSelectedDishFoodIdx,
        selectedDishFoodSegmentIdx: newSelectedDishFoodSegmentIdx,
        numShifts: this.state.numShifts + 1,
      });
    }
  }

  onSegmentAdded(dishFoodIdx) {
    const dishFood = this.state.dishFoods[dishFoodIdx];
    if (!dishFood) {
      alert(`Cannot add segment to invalid dishfood index: ${dishFoodIdx}`)
      return;
    }
    if (this.props.media.length === 0) {
      alert(`Cannot add segment without media`)
      return;
    }
    const newDishFoods = this.state.dishFoods.slice();
    const newDishFood = Object.assign({}, newDishFoods[dishFoodIdx]);
    const newSegments = newDishFood.segments.slice();
    newSegments.push(this.newSegment());
    newDishFood.segments = newSegments;
    newDishFoods[dishFoodIdx] = newDishFood;
    this.setState({
      mode: 'draw_shape',
      dishFoods: newDishFoods,
      selectedDishFoodIdx: dishFoodIdx,
      selectedDishFoodSegmentIdx: newDishFood.segments.length - 1,
      numShifts: this.state.numShifts + 1,
    });
  }

  onSegmentRedraw(dishFoodIdx, dishFoodSegmentIdx) {
    if (this.state.mode === 'draw_shape') {
      // do nothing
    } else {
      this.setState({
        mode: 'draw_shape',
        selectedDishFoodIdx: dishFoodIdx,
        selectedDishFoodSegmentIdx: dishFoodSegmentIdx,
      });
    }
  }

  onSegmentDeleted(dishFoodIdx, segmentIdx) {
    const dishFood = this.state.dishFoods[dishFoodIdx];
    if (!dishFood) {
      alert(`Cannot delete invalid dishfood segment index: ${dishFoodIdx}, ${segmentIdx}`)
      return;
    }
    const segment = dishFood.segments[segmentIdx];
    if (!segment) {
      alert(`Cannot delete invalid dishfood segment index: ${dishFoodIdx}, ${segmentIdx}`)
      return;
    }
    const newDishFoods = this.state.dishFoods.slice();
    const newDishFood = Object.assign({}, newDishFoods[dishFoodIdx]);
    const newSegments = newDishFood.segments.slice();
    const deletedSegment = newSegments.splice(segmentIdx, 1)[0];
    newDishFood.segments = newSegments;
    newDishFoods[dishFoodIdx] = newDishFood;
    let deletedSegments = this.state.deletedSegments;
    if (deletedSegment.persisted) {
      deletedSegments = deletedSegments.slice();
      deletedSegments.push({
        id: deletedSegment.id,
        dishFoodId: newDishFood.id,
      });
    }
    if (this.state.mode === 'draw_shape') {
      this.refs.mediaSelectorComponent.clearDrawingData();
    }
    this.setState({
      mode: 'normal',
      dishFoods: newDishFoods,
      deletedSegments: deletedSegments,
      selectedDishFoodSegmentIdx: -1,
      numShifts: this.state.numShifts + 1,
    });
  }

  onSelectedSegmentShapeFinalized(polygons) {
    if (polygons.length === 0 || polygons[0].length < 3) {
      // saving an empty/degenerate shape is the same as deleting a segment
      this.onSegmentDeleted(
        this.state.selectedDishFoodIdx,
        this.state.selectedDishFoodSegmentIdx,
      );
      return;
    }
    const newDishFoods = this.state.dishFoods.slice();
    const newDishFood = Object.assign({}, newDishFoods[this.state.selectedDishFoodIdx]);
    const newSegments = newDishFood.segments.slice();
    const newSegment = Object.assign({}, newSegments[this.state.selectedDishFoodSegmentIdx]);
    const newLocation = Object.assign({}, newSegment.location);
    newLocation.polygons = polygons;
    newSegment.location = newLocation;
    if (newSegment.mlConfidence != null) {
      newSegment.mlCorrect = false;
    }
    newSegments[this.state.selectedDishFoodSegmentIdx] = newSegment;
    newDishFood.segments = newSegments;
    newDishFoods[this.state.selectedDishFoodIdx] = newDishFood;
    this.setState({
      mode: 'normal',
      dishFoods: newDishFoods,
    });
  }

  onDrawModeCanceled() {
    const segment = this.state.dishFoods[this.state.selectedDishFoodIdx].segments[this.state.selectedDishFoodSegmentIdx];
    if (segment && segment.location.polygons.length === 0) {
      this.onSegmentDeleted(this.state.selectedDishFoodIdx, this.state.selectedDishFoodSegmentIdx)
    } else {
      this.setState({
        mode: 'normal',
      });
    }
  }

  applyFoodStandardPortion(dishFood, overwrite) {
    const food = dishFood.food;
    const mediaPresent = this.props.media.length !== 0;
    let quantity = '';
    let unit;

    if (food != null && food.standardFoodPortion != null) {
      const { standardFoodPortion } = food;
      quantity = standardFoodPortion.quantity;
      unit = standardFoodPortion.unit;
    }
    if (mediaPresent) {
      if (overwrite || (dishFood.presentQuantity == null || dishFood.presentQuantity === '')) {
        dishFood.presentQuantity = quantity;
        dishFood.presentUnit = unit;
      }
    } else {
      if (overwrite || (dishFood.eatenQuantity == null || dishFood.eatenQuantity === '')) {
        dishFood.eatenQuantity = quantity;
        dishFood.eatenUnit = unit;
      }
    }
    dishFood.calculatedEatenGrams = this.calculateEatenGrams(dishFood);
    dishFood.calculatedEatenMililiters = this.calculateEatenMililiters(dishFood);
  }

  calculateEatenGrams(dishFood) {
    let calculatedEatenGrams;
    const eatenUnit = dishFood.eatenUnit ? dishFood.eatenUnit.toLowerCase() : null;
    const presentUnit = dishFood.presentUnit ? dishFood.presentUnit.toLowerCase() : null;
    if (dishFood && +dishFood.eatenQuantity != null) {
      if (eatenUnit !== '%') {
        if (eatenUnit === 'g') {
          calculatedEatenGrams = +dishFood.eatenQuantity;
        } else if (eatenUnit === 'kg') {
          calculatedEatenGrams = +dishFood.eatenQuantity * 1000;
        }
      } else if (+dishFood.presentQuantity != null) {
        if (presentUnit) {
          if (presentUnit === 'g') {
            calculatedEatenGrams = +dishFood.presentQuantity * +dishFood.eatenQuantity / 100;
          } else if (presentUnit === 'kg') {
            calculatedEatenGrams = +dishFood.presentQuantity * +dishFood.eatenQuantity * 10;
          }
        }
      }
    }
    return calculatedEatenGrams
  }

  calculateEatenMililiters(dishFood) {
    let calculatedEatenMililiters;
    const eatenUnit = dishFood.eatenUnit ? dishFood.eatenUnit.toLowerCase() : null;
    const presentUnit = dishFood.presentUnit ? dishFood.presentUnit.toLowerCase() : null;
    if (dishFood && +dishFood.eatenQuantity != null) {
      if (eatenUnit !== '%') {
        if (eatenUnit === 'ml') {
          calculatedEatenMililiters = +dishFood.eatenQuantity;
        } else if (eatenUnit === 'cl') {
          calculatedEatenMililiters = +dishFood.eatenQuantity * 10;
        } else if (eatenUnit === 'dl') {
          calculatedEatenMililiters = +dishFood.eatenQuantity * 100;
        } else if (eatenUnit === 'l') {
          calculatedEatenMililiters = +dishFood.eatenQuantity * 1000;
        }
      } else if (+dishFood.presentQuantity != null) {
        if (presentUnit) {
          if (presentUnit === 'ml') {
            calculatedEatenMililiters = +dishFood.presentQuantity * +dishFood.eatenQuantity / 100;
          } else if (presentUnit === 'cl') {
            calculatedEatenMililiters = +dishFood.presentQuantity * +dishFood.eatenQuantity / 10;
          } else if (presentUnit === 'dl') {
            calculatedEatenMililiters = +dishFood.presentQuantity * +dishFood.eatenQuantity;
          } else if (presentUnit === 'l') {
            calculatedEatenMililiters = +dishFood.presentQuantity * +dishFood.eatenQuantity * 10;
          }
        }
      }
    }
    return calculatedEatenMililiters
  }
}

DishFoodMediaSegmentor.colorFromIndex = (idx) => {
  // Based on https://eleanormaclure.files.wordpress.com/2011/03/colour-coding.pdf
  // and https://pi.math.cornell.edu/~mec/2003-2004/cryptography/subs/frequencies.html
  const colors = [
    '#00998F',
    '#F0A3FF',
    '#FFA405',
    '#808080',
    '#003380',
    '#5EF1F2',
    '#FF0010',
    '#FFCC99',
    '#4C005C',
    '#9DCC00',
  ];
  return colors[idx % colors.length];
};

DishFoodMediaSegmentor.propTypes = {
  ddsgId: PropTypes.string,
  media: PropTypes.array,
  dishFoods: PropTypes.array,
};

export default DishFoodMediaSegmentor;
