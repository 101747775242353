// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within assets/webpack/js and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("react_ujs").detectEvents(); // MUST be started after Turbolinks
require("@rails/activestorage").start();

import "../js/channels";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// --- External JS, accessible anywhere
import "../js/node/babel-polyfill";
import "../js/node/jquery";
import "../js/node/bootstrap";
import "../js/node/moment";
import "../js/node/flatpickr";
import "../js/node/select2";

// --- App JS
import "../js/force-next-turbolinks-visit-reload-on-error.es6";
import "../js/util.es6";
import "../js/lib";
import "../js/general_initialization.es6";
import "../js/dates.es6";
import "../js/forms.es6";
import "../js/dish.es6";
import "../js/food.es6";
import "../js/note_form.es6";
import "../js/components";
import "../js/graphs";

// --- Stimulus config
import { Application } from '@hotwired/stimulus';

const application = Application.start();
application.warnings = true;
application.debug = false;
window.Stimulus = application;

// --- Stimulus components
import DropdownLanguageComponentController from './../../../components/dropdown_language_component/dropdown_language_component_controller';

application.register('dropdown-language-component', DropdownLanguageComponentController);

// Copy to clipboard
import ClipboardJS from 'clipboard';

document.addEventListener("turbolinks:load", () => {
  new ClipboardJS("[data-clipboard-trigger]");
});

ReactRailsUJS.handleEvent('turbolinks:before-render', ReactRailsUJS.handleUnmount)
