import React from "react";
import PropTypes from "prop-types";

class DishFoodMediaSegmentor_SingleMediaCanvas extends React.Component {
  constructor(props) {
    super(props);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onDoubleClick = this.onDoubleClick.bind(this);

    this.state = {
      mouseHover: false,
      mouseCoords: {
        x: 0.0,
        y: 0.0,
      },
      shapePolygons: [],
      lastPolygonClosed: true,
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    this.paintCanvas();
  }
  componentDidUpdate() {
    this.paintCanvas();
  }

  paintLoadingScreen(ctx, dpi, cd) {
    const fontSize = 30 * dpi;
    ctx.fillStyle = '#CCCCCC';
    ctx.fillRect(0, 0, cd.w, cd.h);
    ctx.fillStyle = '#000000';
    ctx.textAlign = 'center';
    ctx.font = `${fontSize}px Arial`;
    ctx.fillText('Loading media...', cd.w/2, cd.h/2 + fontSize/2);
  }
  paintErrorScreen(ctx, dpi, cd) {
    const fontSize = 30 * dpi;
    ctx.fillStyle = '#CCCCCC';
    ctx.fillRect(0, 0, cd.w, cd.h);
    ctx.fillStyle = '#CC0000';
    ctx.textAlign = 'center';
    ctx.font = `${fontSize}px Arial`;
    ctx.fillText('Error loading media!', cd.w/2, cd.h/2 + fontSize/2);
  }
  paintUnexpectedMediaType(ctx, dpi, cd) {
    const fontSize = 30 * dpi;
    ctx.fillStyle = '#FFCCCC';
    ctx.fillRect(0, 0, cd.w, cd.h);
    ctx.fillStyle = '#000000';
    ctx.textAlign = 'center';
    ctx.font = `${fontSize}px Arial`;
    ctx.fillText('Unexpected media type:', cd.w/2, cd.h/2 + fontSize/2);
    ctx.fillText(this.props.media.type, cd.w/2, cd.h/2 + fontSize/2 + fontSize);
  }
  paintImageAndSegments(ctx, dpi, cd) {
    const { obj: image, id: mediaId } = this.props.media;
    const rid = { w: image.width, h: image.height }; // real image dimensions
    const scale = rid.h*cd.w/rid.w<cd.h ? cd.w/rid.w : cd.h/rid.h; // fit smallest dimension
    const ib = {
      l: (cd.w - rid.w * scale)/2,
      t: (cd.h - rid.h * scale)/2,
      r: (cd.w - rid.w * scale)/2 + rid.w * scale,
      b: (cd.h - rid.h * scale)/2 + rid.h * scale,
      w: rid.w * scale,
      h: rid.h * scale,
    }; // image bounds

    // draw image
    {
      ctx.save();
      ctx.translate(ib.l, ib.t);
      ctx.scale(scale, scale);
      ctx.drawImage(image, 0, 0);
      ctx.restore();
    }

    // draw all segments except selected one
    const selDFI = this.props.selectedDishFoodIdx;
    const selSegI = this.props.selectedDishFoodSegmentIdx;
    this.props.dishFoods.forEach((dishFood, dishFoodIdx) => {
      dishFood.segments.forEach((segment, segmentIdx) => {
        if (selDFI === dishFoodIdx && selSegI === segmentIdx) { return; }
        if (segment.mediaId !== mediaId) { return; }
        this.paintSegment(ctx, dpi, ib, dishFoodIdx, segmentIdx);
      });
    });

    if (this.isDrawMode()) {
      this.paintDrawnShape(ctx, dpi, ib);
      this.paintCrosshair(ctx, dpi);
    } else {
      if (selSegI !== -1) {
        this.paintSegment(ctx, dpi, ib, selDFI, selSegI);
      }
    }
  }
  paintSegment(ctx, dpi, ib, dishFoodIdx, segmentIdx) {
    const segment = this.props.dishFoods[dishFoodIdx].segments[segmentIdx];
    const selected = this.props.selectedDishFoodIdx === dishFoodIdx && this.props.selectedDishFoodSegmentIdx === segmentIdx;
    const color = this.props.colorFromIndex(segment.colorIdx);
    const drawPolyPath = () => {
      for (const polygon of segment.location.polygons) {
        polygon.forEach((point, pointIdx) => {
          const x = ib.l + point[0] * ib.w;
          const y = ib.t + point[1] * ib.h;
          pointIdx === 0
            ? ctx.moveTo(x, y)
            : ctx.lineTo(x, y)
          ;
        });
        ctx.closePath();
      }
    }
    if (selected) {
      // green or red background
      ctx.fillStyle = '#00990066'
      ctx.beginPath();
      ctx.moveTo(ib.l       , ib.t);
      ctx.lineTo(ib.l + ib.w, ib.t);
      ctx.lineTo(ib.l + ib.w, ib.t + ib.h);
      ctx.lineTo(ib.l       , ib.t + ib.h);
      drawPolyPath();
      ctx.fill('evenodd');
    }
    let strokeOpacity = 'FF';
    let fillOpacity = 'FF';
    if (selected) { // selected item
      fillOpacity = '00';
      if (this.isDrawMode()) {
        ctx.lineWidth = 1 * dpi;
        ctx.setLineDash([2 * dpi]);
      } else {
        ctx.lineWidth = 5 * dpi;
        ctx.setLineDash([]);
      }
    } else {
      if (this.props.selectedDishFoodIdx === -1 && this.props.selectedDishFoodSegmentIdx === -1) {
        // no selection
        ctx.lineWidth = 1 * dpi;
        fillOpacity = '33';
        ctx.setLineDash([]);
      } else {
        // selection is some other segment
        fillOpacity = '22';
        strokeOpacity =  this.isDrawMode() ? '88' : 'CC';
        ctx.lineWidth = 2 * dpi;
        ctx.setLineDash([4 * dpi]);
      }
    }
    ctx.beginPath();
    drawPolyPath();
    ctx.fillStyle = `${color}${fillOpacity}`;
    ctx.fill('evenodd');
    if (selected) {
      const desiredWidth = ctx.lineWidth / dpi;
      ctx.strokeStyle = '#FFFFFF';
      ctx.lineWidth = (desiredWidth + 2) * dpi;
      ctx.stroke();
      ctx.lineWidth = desiredWidth * dpi;
    }
    ctx.strokeStyle = `${color}${strokeOpacity}`;
    ctx.stroke();
  }
  paintDrawnShape(ctx, dpi, ib) {
    // main shape
    const numPolygons = this.state.shapePolygons.length;
    const segment = this.props.dishFoods[this.props.selectedDishFoodIdx].segments[this.props.selectedDishFoodSegmentIdx];
    const polyColor = this.props.colorFromIndex(segment.colorIdx);
    const strokeWidth = 2 * dpi;
    const startRadius = 2 * dpi;
    const startColor = 'red';
    let startDot;
    const endRadius = 5 * dpi;
    const endColor = 'green';
    let endDot;

    ctx.beginPath();
    ctx.setLineDash([]);
    this.state.shapePolygons.forEach((polygon, polygonIdx) => {
      const numPoints = polygon.length;
      polygon.forEach((point, pointIdx) => {
        const p = {
          x: ib.l + point[0] * ib.w,
          y: ib.t + point[1] * ib.h,
        };
        if (polygonIdx === numPolygons - 1 && !this.state.lastPolygonClosed) {
          if (pointIdx === numPoints - 1) {
            endDot = p;
          } else if (pointIdx === 0) {
            startDot = p;
          }
        }
        if (pointIdx == 0) {
          ctx.moveTo(p.x, p.y);
        } else {
          ctx.lineTo(p.x, p.y);
          if (pointIdx === numPoints - 1 && (this.state.lastPolygonClosed || polygonIdx < numPolygons - 1)) {
            ctx.closePath();
          }
        }
      });
    });
    if (!this.state.lastPolygonClosed && this.state.mouseHover) {
      ctx.lineTo(this.state.mouseCoords.x, this.state.mouseCoords.y);
    }
    // draw markers over first and last points of current polygon
    ctx.fillStyle = `${polyColor}88`;
    ctx.fill('evenodd');
    ctx.lineWidth = strokeWidth + 4;
    ctx.strokeStyle = 'black';
    ctx.stroke();
    ctx.lineWidth = strokeWidth + 2;
    ctx.strokeStyle = 'white';
    ctx.stroke();
    ctx.strokeStyle = polyColor;
    ctx.lineWidth = strokeWidth;
    ctx.stroke();
    if (startDot) {
      ctx.beginPath();
      ctx.ellipse(startDot.x, startDot.y, startRadius + 2 * dpi, startRadius + 2 * dpi, 0, 0, 2 * Math.PI);
      ctx.strokeStyle = 'none';
      ctx.fillStyle = 'black';
      ctx.ellipse(startDot.x, startDot.y, startRadius + 1 * dpi, startRadius + 1 * dpi, 0, 0, 2 * Math.PI);
      ctx.fillStyle = 'white';
      ctx.fill();
      ctx.beginPath();
      ctx.fillStyle = startColor;
      ctx.ellipse(startDot.x, startDot.y, startRadius, startRadius, 0, 0, 2 * Math.PI);
      ctx.fill();
    }
    if (endDot) {
      ctx.beginPath();
      ctx.ellipse(endDot.x, endDot.y, endRadius + 2 * dpi, endRadius + 2 * dpi, 0, 0, 2 * Math.PI);
      ctx.strokeStyle = 'none';
      ctx.fillStyle = 'black';
      ctx.ellipse(endDot.x, endDot.y, endRadius + 1 * dpi, endRadius + 1 * dpi, 0, 0, 2 * Math.PI);
      ctx.fillStyle = 'white';
      ctx.fill();
      ctx.beginPath();
      ctx.fillStyle = endColor;
      ctx.ellipse(endDot.x, endDot.y, endRadius, endRadius, 0, 0, 2 * Math.PI);
      ctx.fill();
    }
  }
  paintCrosshair(ctx, dpi) {
    if (this.state.mouseHover) {
      const mouse = {
        x: this.state.mouseCoords.x,
        y: this.state.mouseCoords.y,
      };
      const ce = { // crosshair extents
        min: 3 * dpi,
        max: 10 * dpi,
      }
      ctx.beginPath();
      ctx.moveTo(mouse.x - ce.max, mouse.y);
      ctx.lineTo(mouse.x - ce.min,  mouse.y);
      ctx.moveTo(mouse.x + ce.min,  mouse.y);
      ctx.lineTo(mouse.x + ce.max, mouse.y);
      ctx.moveTo(mouse.x, mouse.y - ce.max);
      ctx.lineTo(mouse.x, mouse.y - ce.min);
      ctx.moveTo(mouse.x, mouse.y + ce.min);
      ctx.lineTo(mouse.x, mouse.y + ce.max);
      ctx.lineWidth = 2 * dpi;
      ctx.strokeStyle = `#FF0000CC`;
      ctx.stroke();
    }
  }

  paintCanvas() {
    if (!this._isMounted) {
      return;
    }
    const canvas = this.refs.canvas;
    const dpi = window.devicePixelRatio;
    { // handle scaling
      const styleWidth = +getComputedStyle(canvas).getPropertyValue("width").slice(0, -2);
      const styleHeight = +getComputedStyle(canvas).getPropertyValue("height").slice(0, -2);
      canvas.setAttribute('height', styleHeight * dpi);
      canvas.setAttribute('width', styleWidth * dpi);
    }
    const cd = { w: canvas.width, h: canvas.height }; // canvas dimensions
    const ctx = canvas.getContext('2d');
    ctx.save();
    // antialias
    ctx.translate(0.001, 0.001);
    ctx.scale(0.999, 0.999);
    ctx.imageSmoothingEnabled = true;
    if (this.props.media.loaded) {
      if (this.props.media.error) {
        this.paintErrorScreen(ctx, dpi, cd);
      } else {
        switch (this.props.media.type) {
          case 'image':
          this.paintImageAndSegments(ctx, dpi, cd);
          break;
          default:
          this.paintUnexpectedMediaType(ctx, dpi, cd);
        }
      }
    } else {
      this.paintLoadingScreen(ctx, dpi, cd);
    }
    ctx.restore();
  }

  render() {
    return (
      <canvas
        ref="canvas"
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          cursor: this.isDrawMode() ? 'none' : 'auto',
          display: this.props.visible ? 'block' : 'none',
        }}
        onMouseLeave={this.isDrawMode() ? this.onMouseLeave : null}
        onMouseMove={this.isDrawMode() ? this.onMouseMove : null}
        onClick={this.isDrawMode() ? this.onClick : null}
        onDoubleClick={this.isDrawMode() ? this.onDoubleClick : null}
      />
    )
  }

  isDrawMode() {
    return this.props.mode === 'draw_shape';
  }

  onMouseLeave() {
    this.setState({
      mouseHover: false,
    });
  }
  onMouseMove(evt) {
    const $canvas = $(this.refs.canvas);
    this.setState({
      mouseHover: true,
      mouseCoords: {
        x: (evt.pageX - $canvas.offset().left) * window.devicePixelRatio,
        y: (evt.pageY - $canvas.offset().top ) * window.devicePixelRatio,
      },
    });
  }
  onClick(evt) {
    evt.stopPropagation();
    const polys = this.state.shapePolygons.slice();
    if (this.state.lastPolygonClosed) {
      polys.push([]);
    }
    const ib = {}; // image bounds
    const cd = {}; // canvas dimensions
    // convert from screen space to image space
    {
      const image = this.props.media.obj;
      const canvas = this.refs.canvas;
      const dpi = window.devicePixelRatio;
      const styleWidth = +getComputedStyle(canvas).getPropertyValue("width").slice(0, -2);
      const styleHeight = +getComputedStyle(canvas).getPropertyValue("height").slice(0, -2);
      Object.assign (cd, { w: styleWidth * dpi, h: styleHeight * dpi });
      const rid = { w: image.width, h: image.height }; // real image dimensions
      const scale = rid.h*cd.w/rid.w<cd.h ? cd.w/rid.w : cd.h/rid.h; // fit smallest dimensio
      Object.assign(ib, {
        l: (cd.w - rid.w * scale)/2,
        t: (cd.h - rid.h * scale)/2,
        r: (cd.w - rid.w * scale)/2 + rid.w * scale,
        b: (cd.h - rid.h * scale)/2 + rid.h * scale,
        w: rid.w * scale,
        h: rid.h * scale,
      });
    }
    const clamp = (n, min, max) => n < min ? min : n > max ? max : n;
    const pointInImageCoords = [
      clamp((this.state.mouseCoords.x - ib.l) / ib.w, 0, 1),
      clamp((this.state.mouseCoords.y - ib.t) / ib.h, 0, 1),
    ];
    polys[polys.length-1].push(pointInImageCoords);
    this.setState({
      lastPolygonClosed: false,
      shapePolygons: polys,
    });
  }
  onDoubleClick(evt) {
    evt.stopPropagation();
    // remove last point of last polygon, which is an artifact of double-click being two clicks
    // this is similar to a part of #performUndo, but we don't need to store the new data in the state
    const validPolygons = this.state.shapePolygons.slice();
    const lastPolygon = validPolygons[validPolygons.length - 1];
    validPolygons[validPolygons.length - 1] = lastPolygon.slice(0, lastPolygon.length - 1);
    this.props.onSelectedSegmentShapeFinalized(validPolygons);
    this.clearDrawingData();
  }


  onShapeAction(action) {
    switch (action) {
    case 'undo':
      this.performUndo();
      break;
    case 'close-and-continue':
      this.performCloseAndContinueShape();
      break;
    case 'close-and-finalize':
      this.performCloseAndFinalizeShape();
      break;
    case 'cancel':
      this.performCancelDraw();
      break;
    default:
      console.log('Unexpected shape action: ' + action);
    }
  }
  performUndo() {
    if (this.state.shapePolygons.length === 0) {
      // nothing to do
    } else if (this.state.lastPolygonClosed) {
      // open up polygon
      this.setState({ lastPolygonClosed: false });
    } else {
      const lastPolygon = this.state.shapePolygons[this.state.shapePolygons.length - 1];
      if (lastPolygon) {
        if (lastPolygon.length === 1) { // only one point so far
          // remove polygon, and close previous
          this.setState({
            lastPolygonClosed: true,
            shapePolygons: this.state.shapePolygons.slice(0, this.state.shapePolygons.length - 1),
          });
        } else {
          // remove last point of last polygon
          const newPolys = this.state.shapePolygons.slice();
          newPolys[newPolys.length - 1] = lastPolygon.slice(0, lastPolygon.length - 1);
          this.setState({
            shapePolygons: newPolys,
          });
        }
      }
    }
  }
  performCloseAndContinueShape() {
    if (this.state.shapePolygons.length === 0 || this.state.shapePolygons[this.state.shapePolygons.length-1].length < 3) {
      alert('Empty or degenerate shape. No action taken.');
      return;
    }
    this.setState({ lastPolygonClosed: true });
  }
  performCloseAndFinalizeShape() {
    this.props.onSelectedSegmentShapeFinalized(
      this.state.shapePolygons,
    );
    this.clearDrawingData();
  }
  performCancelDraw() {
    this.props.onDrawModeCanceled();
    this.clearDrawingData();
  }

  clearDrawingData() {
    this.setState({
      mouseHover: false,
      mouseCoords: {
        x: 0.0,
        y: 0.0,
      },
      shapePolygons: [],
      lastPolygonClosed: true,
    });
  }
}

DishFoodMediaSegmentor_SingleMediaCanvas.propTypes = {
  visible: PropTypes.bool,
  mode: PropTypes.string,
  media: PropTypes.object,
  dishFoods: PropTypes.array,
  selectedDishFoodIdx: PropTypes.number,
  selectedDishFoodSegmentIdx: PropTypes.number,
  colorFromIndex: PropTypes.func,
  onSelectedSegmentShapeFinalized: PropTypes.func,
  onDrawModeCanceled: PropTypes.func,
};

export default DishFoodMediaSegmentor_SingleMediaCanvas;
