import React from "react";
import PropTypes from "prop-types";

import DishFoodMediaSegmentor_SingleMediaCanvas from "./single_media_canvas.es6.jsx";

class DishFoodMediaSegmentor_MediaSelector extends React.Component {
  constructor(props) {
    super(props);
    this.onChangeMediaSelectionIdxBy = this.onChangeMediaSelectionIdxBy.bind(this);
    this.onClickMarkMediaPrivate = this.onClickMarkMediaPrivate.bind(this);
    this.refPrivacyIconContainer = React.createRef();
    this.refMarkPrivateQuestion = React.createRef();
    this.state = {
      media: this.props.media.map(x => ({
        type: 'image',
        id: x.id,
        privacy: x.privacy,
        url: x.url,
        markingPrivate: false,
        loaded: false,
        obj: new Image(),
      })),
    }
    this.state.media.forEach((media, idx) => {
      const handler = (evt) => {
        this.onMediaLoaded(idx, evt);
      };
      media.obj.onload = handler;
      media.obj.onerror = handler;
      media.obj.src = media.url;
    });
  }

  componentDidUpdate() {
    $(this.refPrivacyIconContainer.current).find("i").tooltipMobileSafe();
    $(this.refMarkPrivateQuestion.current).tooltipMobileSafe();
  }

  renderNoMedia() {
    return (
      <div style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <h1>No media</h1>
      </div>
    );
  }

  renderNumSelectedMediaSegments() {
    const mediaId = this.props.media[this.props.selectedMediaIdx].id;
    let num = 0;
    this.props.dishFoods.forEach(dishFood => {
      dishFood.segments.forEach(segment => {
        if (segment.mediaId === mediaId) {
          ++num;
        }
      });
    });
    return `${num} segment${num === 1 ? '' : 's'}`;
  }

  renderNavigation() {
    const linkStyle = {
      fontSize: '150%',
      fontWeight: '900',
      cursor: 'pointer',
      padding: '0 8px',
      margin: '0',
      userSelect: 'none',
    };
    const disabledLinkMod = {
      cursor: 'not-allowed',
      opacity: '0.4'
    }
    const leftLinkStyle = this.props.selectedMediaIdx === 0
      ? Object.assign({}, linkStyle, disabledLinkMod)
      : linkStyle;
    const rightLinkStyle = this.props.selectedMediaIdx === this.props.media.length - 1
      ? Object.assign({}, linkStyle, disabledLinkMod)
      : linkStyle;
    return (
      <div className="form-group" style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        margin: '0'
      }}>
        <label
          onClick={(evt)=> { evt.stopPropagation(); this.onChangeMediaSelectionIdxBy(-1); }}
          style={leftLinkStyle}
        >
          <i className="fas fa-arrow-left"></i>
        </label>
        <div>
          Image {this.props.selectedMediaIdx + 1} of {this.props.media.length}
          <br />
          ({this.renderNumSelectedMediaSegments()})
        </div>
        <label
          onClick={(evt)=> { evt.stopPropagation(); this.onChangeMediaSelectionIdxBy(1); }}
          style={rightLinkStyle}
        >
          <i className="fas fa-arrow-right"></i>
        </label>
      </div>
    );
  }

  renderPrivacyBadge() {
    if (this.props.selectedMediaIdx === -1) {
      return null;
    }
    const media = this.state.media[this.props.selectedMediaIdx];
    const badgeHtml = RubyVars.privacyBadges[media.privacy];
    if (badgeHtml == null) {
      return null;
    }
    return (
      <div className="media-privacy">
        <div
          className="privacy-badge"
          ref={this.refPrivacyIconContainer}
          dangerouslySetInnerHTML={{ __html: badgeHtml }}
        />
        {this.renderMarkPrivateButton(media)}
      </div>
    );
  }

  renderMarkPrivateButton() {
    if (this.props.selectedMediaIdx === -1) {
      return null;
    }
    const media = this.state.media[this.props.selectedMediaIdx];
    if (media.privacy === 'private') {
      return null;
    }
    if (media.markingPrivate) {
      return (
        <div className="mark-private ml-3 text-muted">
          <i className="fas fa-spin mr-2 fa-sync" aria-hidden="true"></i>
          {RubyVars.i18n.markPrivateProcessingText}
        </div>
      );
    }

    return (
      <div className="mark-private ml-2">(
        <button
          className="ddsg-media-privatization-button btn btn-link p-0 border-0"
          type="button"
          onClick={this.onClickMarkMediaPrivate}
        >
          {RubyVars.i18n.markPrivateButtonText}
        </button>
        <i
          className="fas small ml-1 fa-question-circle"
          title={RubyVars.i18n.markPrivateTooltip}
          ref={this.refMarkPrivateQuestion}
        ></i>
      )</div>
    );
  }

  render() {
    return (
      <div>
        <div className="segmentation-image">
          {this.state.media.length > 0 ? this.state.media.map((media, mediaIdx) => (
            <DishFoodMediaSegmentor_SingleMediaCanvas
              key={`single-media-canvas-${mediaIdx}`}
              ref={`single-media-canvas-${mediaIdx}`}
              visible={mediaIdx === this.props.selectedMediaIdx}
              mode={this.props.mode}
              media={this.state.media[mediaIdx]}
              dishFoods={this.props.dishFoods}
              selectedDishFoodIdx={this.props.selectedDishFoodIdx}
              selectedDishFoodSegmentIdx={this.props.selectedDishFoodSegmentIdx}
              colorFromIndex={this.props.colorFromIndex}
              onSelectedSegmentShapeFinalized={this.props.onSelectedSegmentShapeFinalized}
              onDrawModeCanceled={this.props.onDrawModeCanceled}
            />
          )) : this.renderNoMedia()}
        </div>
        {this.renderPrivacyBadge()}
        {this.props.media.length > 1 ? this.renderNavigation() : null}
      </div>
    );
  }

  onClickMarkMediaPrivate() {
    const mediaIdx = this.props.selectedMediaIdx;
    if (mediaIdx === -1) {
      return;
    }
    const media = this.state.media[mediaIdx];
    if (media.markingPrivate || !confirm(RubyVars.i18n.markPrivateConfirmText)) {
      return;
    }
    const updateMediaState = opts => {
      const newMediaArr = this.state.media.slice();
      newMediaArr[mediaIdx] = Object.assign({}, newMediaArr[mediaIdx], opts);
      this.setState({media: newMediaArr});
    };
    updateMediaState({markingPrivate: true});

    $.ajax({
      url: `/dish_dupe_sibling_groups/${this.props.ddsgId}/media/${media.id}/privatizations`,
      method: "POST",
      success: response => {
        updateMediaState({privacy: 'private', markingPrivate: false});
      },
      error: xhr => {
        try {
          alert(JSON.parse(xhr.responseText).errors.join("\n"));
        } catch (e) {
          alert(`${xhr.status}: ${xhr.statusText}\n\n${xhr.responseText}`)
        }
        updateMediaState({markingPrivate: false});
      },
    });
  }

  onChangeMediaSelectionIdxBy(change) {
    this.props.onSelectedMediaIdxChanged(
      this.props.selectedMediaIdx + change
    );
  }

  onMediaLoaded(mediaIdx, event) {
    const newMediaArr = this.state.media.slice();
    const newMediaOpts = { loaded: true };
    if (event.type === 'error') {
      newMediaOpts.error = true;
    }
    newMediaArr[mediaIdx] = Object.assign(
      {},
      newMediaArr[mediaIdx],
      newMediaOpts,
    );
    this.setState({
      media: newMediaArr,
    });
  }

  onShapeAction(action) {
    const canvasComponent = this.refs[`single-media-canvas-${this.props.selectedMediaIdx}`];
    canvasComponent.onShapeAction(action);
  }

  clearDrawingData() {
    const numMedia = this.props.media.length;
    for (let i = 0; i < numMedia; ++i) {
      const canvasComponent = this.refs[`single-media-canvas-${i}`];
      canvasComponent.clearDrawingData();
    }
  }
}

DishFoodMediaSegmentor_MediaSelector.propTypes = {
  ddsgId: PropTypes.string,
  media: PropTypes.array,
  mode: PropTypes.string,
  selectedMediaIdx: PropTypes.number,
  onSelectedMediaIdxChanged: PropTypes.func,
  dishFoods: PropTypes.array,
  selectedDishFoodIdx: PropTypes.number,
  selectedDishFoodSegmentIdx: PropTypes.number,
  colorFromIndex: PropTypes.func,
  onSelectedSegmentShapeFinalized: PropTypes.func,
  onDrawModeCanceled: PropTypes.func,
};

export default DishFoodMediaSegmentor_MediaSelector;
