// init functions
import {
  convertToNumber,
  getFiltered,
  projection,
  sortByDate,
  completeMissingData
} from './helpers'

import jQuery from 'jquery'
import { frTimeLocale, deTimeLocale } from './locales'
import * as d3 from "d3"

export default function initHistogram() {
  var holder = jQuery('#histogram');
  var locale = holder.data('locale');

  if(locale === "fr") {
    d3.timeFormatDefaultLocale(frTimeLocale);
  } else if(locale === "de") {
    d3.timeFormatDefaultLocale(deTimeLocale);
  }

  if (holder.length) {
    var src = holder.data('src');

    if (src) {
      d3.csv(src).then(function(data) {
        // prepare data
        var md = {
          'date': ['x'],
          'somme': ['y', convertToNumber],
        };

        var kCalToDateFiltered = getFiltered(data, 'type', 'energy_kcal_eaten');

        var quantityToDateFiltered = getFiltered(data, 'type', 'eaten_quantity_gram');

        var kCalToDate = projection(md)(kCalToDateFiltered)
          .sort(sortByDate);

        var quantityToDate = projection(md)(quantityToDateFiltered)
          .sort(sortByDate);

        var dataset = ({
          'energy': {
            data: kCalToDate,
            xTitle: '',
            yTitle: 'Kcal',
          },
          'quantity': {
            data: quantityToDate,
            xTitle: '',
            yTitle: 'g',
          }
        });

        ResponsiveHelper.addRange({
          '768..': {
            on: function() {
              holder.histogram({
                data: dataset,
              });
            },
            off: function() {
              holder.histogram('destroy');
            }
          },
          '..767': {
            on: function() {
              holder.histogram({
                data: dataset,
                barsCount: 15,
                dateFormat: '%d/%m/%y ',//'Nov 25'
                barsContainerPadding: 10,
                leftPadding: 38,
                yAxisLeftPadding: 0,
                barsMarginLeft: 5,
                lineHeight: 15,
              });
            },
            off: function() {
              holder.histogram('destroy');
            }
          }
        });
      });
    } else {
      holder.addClass(errorClass);
    }
  }
}

/*
 * jQuery Histogram plugin
 */
;(function($) {
  'use strict';

  function Histogram(opt) {
    this.holder = $(opt.holder);
    this.options = $.extend({
      data: {},
      svgHolder: '.svg-holder',
      filterBtn: '.graph-switcher .opener',
      barsCount: 20,
      dateFormat: '%a %d/%m/%y',//'Mon 18, 11' https://github.com/d3/d3-time-format#locale_format
      barsContainerPadding: 30,
      barsMarginLeft: 8,
      leftPadding: 67,
      yAxisLeftPadding: 30,
      lineHeight: 18,
      sepHeight: 13,
    }, opt);

    this.init();
  }

  Histogram.prototype = {
    init: function() {
      this.data = {};
      this.visibleData = [];
      this.visibleDataStartIndex = 0;

      this.dateFormat = d3.timeFormat(this.options.dateFormat);
      this.dateParser = d3.timeParse(this.options.dateFormat);

      this.lineHeight = this.options.lineHeight;
      this.sepHeight = this.options.sepHeight;
      this.sepMargin = 2;
      this.marginBottom = 4;
      this.marginTop = this.lineHeight/2;
      this.leftPadding = this.options.leftPadding;
      this.yAxisLeftPadding = this.options.yAxisLeftPadding;

      this.barsMarginLeft = this.options.barsMarginLeft;
      this.barsContainerPadding = this.options.barsContainerPadding;

      this.findElements();
      this.refreshHolderSize();
      this.setActiveData();
      this.attachEvents();
      this.isInited = true;
    },

    findElements: function() {
      this.win = $(window);
      this.svgHolder = this.holder.find(this.options.svgHolder);
      this.filter = this.holder.find(this.options.filterBtn);
    },

    attachEvents: function() {
      var self = this;
      this.svgHolder.addClass('no-dragging');

      this.removeClassHandler = function() {
        self.flag = false;
        self.resizeFn();
      };
      this.resizeHandler = function() {
        if(!self.flag) {
          self.flag = true;
        }
        clearTimeout(self.resizeTimer);
        self.resizeTimer = setTimeout(self.removeClassHandler, 500);
      };

      this.resizeFn = function() {
        this.refreshHolderSize();
        this.scaleData();
        this.setPositionParams();
      };

      this.startDragFn = function() {
        self.svgHolder.removeClass('no-dragging');
        d3.select(this).classed('dragging', true);
      };

      this.dragFn = function() {
        if (self.options.barsCount >= self.data.data.length) {
          return;
        }
        self.isDragging = true;
        var indexChanged = self.numIndex(d3.event.dx * -1);
        if (indexChanged) {
          self.setActiveData();
        }
      };

      this.endDragFn = function() {
        self.isDragging = false;
        self.svgHolder.addClass('no-dragging');
        d3.select(this).classed('dragging', false);
      };

      this.overFn = function(e) {
        if (!self.isDragging && self.svgHolder.hasClass('no-dragging')) {
          self.svgHolder.removeClass('no-dragging');
        }
      };

      this.outFn = function(e) {
        var curTarget = $(e.currentTarget);
        var relTarget = $(e.relatedTarget);
        if (!relTarget.is(curTarget) && !relTarget.closest(curTarget).length) {
          self.svgHolder.addClass('no-dragging');
        }
      };

      this.win.on('resize orientationchange', this.resizeHandler);

      this.svgHolder.on('touchstart mouseover', this.overFn);
      this.svgHolder.on('touchend mouseout', this.outFn);

      d3.select(this.svgHolder[0]).call(
        d3.drag()
          .on('start', this.startDragFn)
          .on('drag', this.throttle(this.dragFn, 70))
          .on('end', this.endDragFn)
      );
    },

    buildGraph: function() {
      this.svg = d3
        .select(this.svgHolder[0])
        .append('svg');

      // x axis
      this.xGroup = this.svg
        .append('g')
        .attr('class', 'x-group')

      this.xGroups = this.xGroup
        .selectAll('text')
        .data(this.xAxisValues)
        .enter()
        .append('g');

      this.xGroups
        .append('text')
        .attr('text-anchor', 'middle')
        .text((d) => d);

      this.xGroups
        .append('rect')
        .attr('width', '1')
        .attr('height', '13')
        .attr('y', '-33');

      // y axis
      this.yGroup = this.svg.append('g')
        .attr('class', 'y-group');

      this.yGroup
        .selectAll('g')
        .data(this.yAxisValues)
        .enter()
        .append('g')
        .append('text')
        .attr('alignment-baseline', 'middle')
        .text((d) => d);

      // y title
      this.titleY = this.svg.append('text')
        .attr('class', 'title-y')
        .text(this.data.yTitle)
        .attr('text-anchor', 'middle');

      // horizontal lines
      this.horizLines = this.svg.append('g')
        .attr('class', 'lines-group')
        .selectAll('line')
          .data(this.yAxisValues)
        .enter().append('line');

      //bars
      this.barGroup = this.svg.append('g')
        .attr('class', 'bars-group');

      this.barGroups = this.barGroup
        .selectAll('g')
          .data(this.visibleData)
        .enter()
        .append('g');

      this.barRect = this.barGroups
        .append('rect');
    },

    setPositionParams: function() {
      var self = this;
      var bottomBoundCentered = this.bottomBound;
      var startLeft = this.leftPadding + this.barsContainerPadding;
      var barWidth = (this.barsFullWidth - ((this.daysCount-1) * this.barsMarginLeft)) /
              this.daysCount;

      if (barWidth > 30) { barWidth = 30; } // MFR edit: usable massive datasets

      this.svg
        .attr('width', this.svgHolder.outerWidth())
        .attr('height', this.svgHolder.outerHeight());

      // x axis
      this.xGroup
        .attr('transform', 'translate(0, ' + (this.height) + ')');

      this.xGroups
        .attr('transform', function(d) {
          return 'translate(' + self.xScaled(self.dateParser(d)) + ', 0)'
        });

      // y axis
      this.yGroup
        .attr('transform', 'translate(' + this.yAxisLeftPadding + ', 0)');

      this.yGroup
        .selectAll('g')
        .attr('transform', function(d) {
          return 'translate(0, ' + (bottomBoundCentered - self.yScaled(d)) + ')';
        });

      // y title
      this.titleY
        .attr('transform', 'translate(' + (this.lineHeight - 2) + ', ' + bottomBoundCentered/2 + ') rotate(-90 0 0)')

      // horizontal lines
      this.horizLines
        .attr('x1', this.leftPadding)
        .attr('y1', bottomBoundCentered)
        .attr('x2', this.width)
        .attr('y2', bottomBoundCentered)
        .attr('transform', function(d) {
          return 'translate(0, -' + (bottomBoundCentered - self.lineHeight - self.yScaled(d)) + ')';
        });

      //bars
      this.barGroup
        .attr('transform', 'translate(0, ' + (this.lineHeight) + ')');

      this.barGroups
        .attr('transform', function(d) {
          return 'translate(' +
            ( self.xScaled(self.dateParser(self.toDateFormat(new Date(d.x)))) - barWidth/2 )+
            ', ' + (bottomBoundCentered - self.yScaled(d.y) - self.lineHeight) +
          ')'
        });

      this.barRect
        .attr('width', barWidth)
        .attr('height', function(d) {
          return self.yScaled(d.y);
        });
    },

    setDataPart: function(fromIndex) {
      this.visibleData = this.getVisibleData(fromIndex);
    },

    getVisibleData: function(fromIndex) {
      var result = [];

      result = this.data.data.slice(fromIndex, fromIndex + this.options.barsCount);

      if (result.length === 0) { return []; } // MFR edit: prevent crash

      var first = result[0].x;
      var last = result[result.length-1].x;

      this.daysCount = d3.timeDay.count(
        d3.timeDay.floor(new Date(first)),
        d3.timeDay.ceil(new Date(last))
      );

      return result;
    },

    scaleData: function() {
      var self = this;

      this.xScaled = d3.scaleLinear()
        .domain(d3.extent(this.xArrayData).map(function(el){
          return self.dateParser(self.dateFormat(new Date(el)));
        }))
        .range([this.leftPadding + this.barsContainerPadding, this.width - this.barsContainerPadding]);

      this.yScaled = d3.scaleLinear()
        .domain(d3.extent(this.yAxisValues))
        .range([0, this.bottomBound - this.lineHeight]);
    },

    getAxisValues: function() {
      var self = this;
      this.xArrayData = this.visibleData.map(function(el) {
        return el.x;
      });
      this.yArrayData = this.visibleData.map(function(el) {
        return el.y;
      });

      this.xAxisValues = this.xArrayData.reduce(function(acc, el) {
        var date = new Date(el);
        var day = date.getUTCDay();

        if (day === 1) {
          acc.push(self.toDateFormat(date));
        }

        return acc;
      }, []);

      // Added by PeterDV, when all visible data on graph is displayed is below 750
      // the axis ticks calculated below breaks, only showing 0 as an axis value.
      var maxY = d3.max(this.yArrayData) < 1000 ? 1000 : d3.max(this.yArrayData);
      this.yAxisValues = d3.ticks(0, Math.ceil(maxY/1000) * 1000, maxY/1000);
    },

    refreshHolderSize: function() {
      this.width = this.svgHolder.outerWidth();
      this.height = this.svgHolder.outerHeight() - this.marginBottom;
      this.bottomBound = this.height - this.lineHeight - this.sepHeight - this.sepMargin;
      this.barsFullWidth = this.width - this.leftPadding - (this.barsContainerPadding * 2);
    },

    toDateFormat: function(date) {
      return this.dateFormat(date);
    },

    setActiveData: function(key) {
      if (this.isInited) {
        this.destroySvg();
      }

      if (typeof key === 'undefined') {
        key = this.filter[0].dataset.value;
      }

      this.data = this.options.data[key];
      this.data.data = completeMissingData(this.data.data, 'integer');

      this.setDataPart(this.visibleDataStartIndex);
      this.getAxisValues();
      this.scaleData();

      this.buildGraph();
      this.setPositionParams();
    },

    numIndex: function(dx) {
      var from = 0;
      var to = this.data.data.length - this.options.barsCount;

      this.prevIndex = this.visibleDataStartIndex;

      if (this.visibleDataStartIndex >= from && this.visibleDataStartIndex <= to) {
        this.visibleDataStartIndex += dx;
      }

      if (this.visibleDataStartIndex < from) {
        this.visibleDataStartIndex = 0;
      }

      if (this.visibleDataStartIndex > to) {
        this.visibleDataStartIndex = to;
      }

      return this.prevIndex !== this.visibleDataStartIndex;
    },

    destroySvg: function() {
      this.svgHolder.html('');
      this.data = {};

      this.visibleData = [];
      this.xArrayData = [];
      this.yArrayData = [];
      this.xAxisValues = [];
      this.yAxisValues = [];
      this.xScaled = function() {};
      this.yScaled = function() {};
    },

    destroy: function() {
      var self = this;
      this.win.off('resize orientationchange', this.resizeHandler);

      d3.select(this.svgHolder[0]).on('.drag', null);

      clearTimeout(this.resizeTimer);
      clearTimeout(this.throttleTimer);

      this.svgHolder.off('touchstart mouseover', this.overFn);
      this.svgHolder.off('touchend mouseout', this.outFn);
      this.svgHolder.removeClass('dragging');
      this.svgHolder.removeClass('no-dragging');

      this.destroySvg();

      this.holder.removeData('graph_api_Histogram');

      var keys = Object.keys(this);
      keys.forEach(function(key) {
        var type = typeof self[key];
        if (self.hasOwnProperty(key)) {
          self[key] = (type === 'function' || type === 'object' || type === 'array') ?
            null : undefined;
        }
      });
    },

    throttle: function (func, timeout) {
      var self = this;
      var execute = true;
      return function () {
        var args = [].slice.call(arguments);
        if (!execute) {
          return;
        }
        execute= false;
        func.apply(self, args);
        self.throttleTimer = setTimeout(function() {
          execute = true;
        }, timeout);
      };
    }
  };

  // jQuery plugin interface
  $.fn.histogram = function(opt) {
    var args = Array.prototype.slice.call(arguments);
    var method = args[0];

    return this.each(function() {
      var $holder = jQuery(this);
      var instance = $holder.data('graph_api_Histogram');

      if (typeof opt === 'object' || typeof opt === 'undefined') {
        $holder.data('graph_api_Histogram', new Histogram($.extend({
          holder: this
        }, opt)));
      } else if (typeof method === 'string' && instance) {
        if (typeof instance[method] === 'function') {
          args.shift();
          instance[method].apply(instance, args);
        }
      }
    });
  };
}(jQuery));
