import React from "react";
import PropTypes from "prop-types";

import DishFoodMediaSegmentor_SingleDishFoodFields from "./single_dish_food_fields.es6.jsx";

class DishFoodMediaSegmentor_DishFoodFields extends React.Component {
  renderNoSelection() {
    return (
      <div style={{
        display: 'table',
        height: '100%',
        width: '100%'
      }}>
        <span style={{
          display: 'table-cell',
          verticalAlign: 'middle',
          textAlign: 'center'
        }}>
          No dishfood selected
        </span>
      </div>
    );
  }

  render() {
    return (
      <div className="segmentation-segments">
        {this.props.dishFoods.map((dishFood, dishFoodIdx) => (
          <DishFoodMediaSegmentor_SingleDishFoodFields
            key={`single-dishfood-fields-${dishFood.componentGuid}`}
            ddsgId={this.props.ddsgId}
            dishFood={dishFood}
            dishFoodIdx={dishFoodIdx}
            selectedDishFoodIdx={this.props.selectedDishFoodIdx}
            selectedDishFoodSegmentIdx={this.props.selectedDishFoodSegmentIdx}
            selectedDishFoodWithFoodEnergyLimitsValidator={dishFood.withFoodEnergyLimitsValidator}
            selectedMediaId={this.props.selectedMediaId}
            onDishFoodFoodChanged={this.props.onDishFoodFoodChanged}
            onDishFoodAmtChanged={this.props.onDishFoodAmtChanged}
            onDishFoodDeleted={this.props.onDishFoodDeleted}
            onSegmentAdded={this.props.onSegmentAdded}
            onSegmentRedraw={this.props.onSegmentRedraw}
            onSegmentDeleted={this.props.onSegmentDeleted}
          />
        ))}
        { this.props.selectedDishFoodIdx === -1 ? this.renderNoSelection() : null }
      </div>
    );
  }
}

DishFoodMediaSegmentor_DishFoodFields.propTypes = {
  ddsgId: PropTypes.string,
  dishFoods: PropTypes.array,
  selectedDishFoodIdx: PropTypes.number,
  selectedDishFoodSegmentIdx: PropTypes.number,
  selectedMediaId: PropTypes.number,
  onDishFoodFoodChanged: PropTypes.func,
  onDishFoodAmtChanged: PropTypes.func,
  onDishFoodDeleted: PropTypes.func,
  onSegmentAdded: PropTypes.func,
  onSegmentRedraw: PropTypes.func,
  onSegmentDeleted: PropTypes.func,
};

export default DishFoodMediaSegmentor_DishFoodFields;
