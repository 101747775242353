$(document).on('turbolinks:load', function() {
  if (window.moment != null && RubyVars.I18nLocale != null) {
    moment.locale(RubyVars.I18nLocale);
  }

  if (RubyVars.correctedUrl != null) {
    history.replaceState(history.state, '', RubyVars.correctedUrl);
  }

  $.fn.select2.amd.require(['select2/diacritics'], function(DIACRITICS) {
    window.MFR = window.MFR || {};
    if (MFR.stripDiacritics == null) {
      MFR.stripDiacritics = function(text) {
        // Used 'uni range + named function' from https://jsperf.com/diacritics/18
        function match(a) {
          return DIACRITICS[a] || a;
        }
        return text.replace(/[^\u0000-\u007E]/g, match);
      };
    }
  });

  const statusCode = +$('.app-main').data('code') || 0;
  const statusCodeFamily = (statusCode / 100) | 0;
  if (statusCodeFamily === 4 || statusCodeFamily === 5) {
    TurbolinksAppErrorListener.errors.push('server');
  }

  $('[data-toggle="tooltip"]').tooltipMobileSafe();

  $('[data-toggle="datepicker"]').datePicker();

  $('[data-toggle="dropdown"]').dropdown();

  const $pending_annotation_count = $('.pending_annotations_count');
  if ($pending_annotation_count.length > 0) {
    $.ajax({
      url: '/api/site/pending_annotations_count',
      success: res => {
        $pending_annotation_count.text(res.data.count.toString())
      },
      error: xhr => { $pending_annotation_count.text('!') },
    });
  }

  $.query.parseNew(location.search, location.hash);

  handleHighlightedElements();

  $('.autorefresh').on('change', function() {
    const $this = $(this);
    $('.autorefresh').attr('disabled', 'disable').addClass('no-action-allowed');
    $this.after(' <i class="fas fa-cog fa-spin"/>');
    window.location.search = $.query.set(
      $this.attr('name'),
      $this.val(),
    ).set('page', null);
  });
});

function handleHighlightedElements() {
  let hl_ids = [];
  const hl = $.query.get('highlight');
  if (Array.isArray(hl)) {
    hl_ids.push(...hl);
  } else {
    hl_ids.push(hl);
  }
  hl_ids = hl_ids.filter(val => val != null && val !== '');
  if (hl_ids.length > 0) {
    $(`#${hl_ids.join(',#')}`).addClass('bg-warning');
  }
}

new MutationObserver(function(mutations) {
  for (let mutationIdx = 0; mutationIdx < mutations.length; ++mutationIdx) {
    const newNodes = mutations[mutationIdx].addedNodes;
    if (newNodes == null) { continue; }
    for (let newNodeIdx = 0; newNodeIdx < newNodes.length; ++newNodeIdx) {
      const newNode = newNodes[newNodeIdx];
      const nodeClasses = newNode.classList;
      if (nodeClasses == null) { continue; }
      if (nodeClasses.contains('async-script')) {
        eval(newNode.textContent);
      }
    }
  }
}).observe(document, {
  subtree: true,
  childList: true,
});
