import React from "react";
import PropTypes from "prop-types";

import DishFoodMediaSegmentor_SingleSegmentFields from "./single_segment_fields.es6.jsx"

class DishFoodMediaSegmentor_SingleDishFoodFields extends React.Component {
  constructor(props) {
    super(props);
    this.onFoodSourceChanged = this.onFoodSourceChanged.bind(this);
    this.onIgnoreEnergyValidation = this.onIgnoreEnergyValidation.bind(this);
    this.onPresentQuantityChange = this.onPresentQuantityChange.bind(this);
    this.onEatenQuantityChange = this.onEatenQuantityChange.bind(this);
    let extraFood;
    const { foodId, foodDisplayNameHtml } = this.props.dishFood;
    if (foodId == null || RubyVars.annotatableFoods.findIndex(x => x.id === foodId) === -1) {
      extraFood = {
        id: foodId,
        text: foodDisplayNameHtml,
      }
    }
    this.state = {
      foodId: +this.props.dishFood.foodId,
      foodSetId: +this.props.dishFood.foodSetId,
      foodSetName: +this.props.dishFood.foodSetId,
      foodDisplayNameHtml: this.props.dishFood.foodDisplayNameHtml,
      foodSource: 'mfr',
      withFoodEnergyLimitsValidator: this.props.selectedDishFoodWithFoodEnergyLimitsValidator,
      annotatableFoods: [],
      extraFood: extraFood,
      presentQuantity: this.props.dishFood.presentQuantity,
      eatenQuantity: this.props.dishFood.eatenQuantity || '',
      calculatedEatenGrams: this.props.dishFood.calculatedEatenGrams,
      calculatedEatenMililiters: this.props.dishFood.calculatedEatenMililiters
    };
    this.foodSources = ['mfr', 'fr'];
  }

  componentDidMount() {
    $(this.refs.self).makeInputNumbersLocaleSafe();
    $([
      this.refs.ttspan_deleteDishFood,
      this.refs.ttspan_presentHelpBox,
      this.refs.ttspan_eatenHelpBox,
    ]).tooltipMobileSafe();
    this.setupFoodSelection();
  }

  componentDidUpdate(prevProps, prevState) {
    let newState = {};
    const prevValPresent = prevProps.dishFood.presentQuantity;
    const prevStrPresent = prevValPresent != null ? prevValPresent.toString() : '';
    const currValPresent = this.props.dishFood.presentQuantity;
    const presentQuantity = currValPresent != null ? currValPresent.toString() : '';
    if (prevStrPresent !== presentQuantity) {
      newState['presentQuantity'] = this.props.dishFood.presentQuantity;
    }

    const prevValEaten = prevProps.dishFood.eatenQuantity;
    const prevStrEaten = prevValEaten != null ? prevValEaten.toString() : '';
    const currValEaten = this.props.dishFood.eatenQuantity;
    const eatenQuantity = currValEaten != null ? currValEaten.toString() : '';
    if (prevStrEaten !== eatenQuantity) {
      newState['eatenQuantity'] = this.props.dishFood.eatenQuantity;
    }

    if (Object.keys(newState).length != 0 || prevProps.dishFood.presentUnit != this.props.dishFood.presentUnit || prevProps.dishFood.eatenUnit != this.props.dishFood.eatenUnit) {
      newState['calculatedEatenGrams'] = this.props.dishFood.calculatedEatenGrams;
      newState['calculatedEatenMililiters'] = this.props.dishFood.calculatedEatenMililiters;
      this.setState(newState);
    }

    if (prevState.foodSource !== this.state.foodSource) {
      switch (this.state.foodSource) {
        case 'mfr':
        this.setupFoodSelection();
        this.props.onDishFoodFoodChanged(this.props.dishFoodIdx, {
          foodDisplayNameHtml: this.state.foodDisplayNameHtml,
        });
        break;
        case 'fr':
        this.props.onDishFoodFoodChanged(this.props.dishFoodIdx, {
          foodDisplayNameHtml: 'New Barcode'
        }, true);
        if (prevProps.dishFood.food != null) {
          this.setState({
            extraFood: prevProps.dishFood.food
          });
        }
        break;
      }
    }
  }

  renderHelpBox(type) {
    let title;
    switch(type) {
    case 'present':
      title = RubyVars.i18n.dishfoodPresentAmountTooltip;
      break;
    case 'eaten':
      title = RubyVars.i18n.dishfoodEatenAmountTooltip;
      break;
    default:
      return '';
    }
    return (
      <i
        ref={`ttspan_${type}HelpBox`}
        className="fas fa-info-circle ml-2"
        data-toggle="tooltip"
        title={title}
      ></i>
    );
  }

  renderFoodSelectionFields() {
    const { dishFood } = this.props;
    switch (this.state.foodSource) {
    case 'mfr':
      return (<select
        className="select2"
        ref="nameSelect"
        name={this.fieldName('food_id')}
        defaultValue={this.state.foodId || -1}
        data-ajax--url={RubyVars.annotatableFoodsUrl}
        // NOTE: we cannot use onChange with select2
      >
        <option disabled value="-1" data-force-sort="-1">-- {RubyVars.i18n.foodDropDownSelect} --</option>
        {RubyVars.annotatableFoods.map((food, i) => (
          <option key={`annotatable_${food.id}`} value={food.id}>
            {food.text}
          </option>
        ))}
        {this.state.extraFood ? (
          <React.Fragment>
            <option disabled value="-1" data-force-sort="1">-- {RubyVars.i18n.foodDropDownNotAnnotatable} --</option>
            <option value={this.state.extraFood.id} data-force-sort="2">{this.state.extraFood.text}</option>
          </React.Fragment>
        ) : null}
      </select>);
      break;
    case 'fr':
      return (
        <React.Fragment>
          <input
            name={this.fieldName('food_attributes][barcode')}
            type="text"
            autoComplete="off"
            className="form-control barcode-input"
            placeholder="Barcode"
          />
        </React.Fragment>
      );
    }
  }

  renderPresentAmountFields() {
    const { dishFood, dishFoodIdx, selectedMediaId } = this.props;
    const { food } = dishFood;
    const allowedUnits = ['g', 'ml'];
    dishFood.presentUnit ||= 'g';
    const checkedUnit = dishFood.presentUnit;
    let dishFoodElement;
    if (selectedMediaId == null) {
      // this field makes no sense if there is no media
      return null;
    }
    if (food == null || this.state.foodSource === 'fr' || food.standardFoodPortion == null) {
      dishFoodElement = <div className="form-group">
        <label>Present unit</label>
        <div className="btn-group btn-group-toggle" data-toggle="buttons">
          {allowedUnits.map((unit) => (
            <label
              key={`unit-${unit}-${dishFood.componentGuid}`}
              className={`btn btn-secondary${checkedUnit === unit ? ' active' : ''}`}
              onClick={() => this.props.onDishFoodAmtChanged(dishFoodIdx, { presentUnit: unit })}
            >
              <input
                type="radio"
                name={this.fieldName('present_unit')}
                defaultValue={unit}
                checked={checkedUnit === unit}
                readOnly
              />
              {unit}
            </label>
          ))}
        </div>
      </div>
    }
    const presentQuantity = this.state.presentQuantity == null ? '' : +this.state.presentQuantity
    return (<div className="segment-quantification">
      { dishFoodElement }
      <div className="form-group">
        <label>Present amount{this.renderHelpBox('present')}</label>
        <div className="input-group">
          <input
            ref="presentQuantityInput"
            name={this.fieldName('present_quantity')}
            lang="en"
            type="text"
            inputMode="numeric"
            autoComplete="off"
            value={presentQuantity}
            className="form-control number-safe"
            placeholder=""
            aria-label=""
            aria-describedby="basic-addon"
            onChange={this.onPresentQuantityChange}
            onKeyUp={this.onPresentQuantityChange}
          />
          <input
            type="hidden"
            name={this.fieldName('present_unit')}
            value={checkedUnit || ''}
          />
          <div className="input-group-append">
            <span className="input-group-text">{checkedUnit || '?'}</span>
          </div>
        </div>
      </div>
    </div>);
  }

  renderEatenAmountFields() {
    const { dishFood, dishFoodIdx, selectedMediaId } = this.props;
    const { food } = dishFood;
    let allowedUnits = [];
    let standardFoodPortionUnit;
    if (food && food.standardFoodPortion && food.standardFoodPortion.unit != null) {
      standardFoodPortionUnit = food.standardFoodPortion.unit;
      allowedUnits.push(standardFoodPortionUnit);
    }
    if (this.state.foodSource === 'fr' || standardFoodPortionUnit == null) {
      allowedUnits = allowedUnits.concat(['g', 'ml'])
    }
    if (dishFood.eatenUnit != null) {
      allowedUnits.push(dishFood.eatenUnit);
    }
    if (selectedMediaId != null) {
      allowedUnits.push('%');
    }
    allowedUnits = [...new Set(allowedUnits)];
    const hiddenUnit = dishFood.eatenUnit != null && !allowedUnits.includes(dishFood.eatenUnit) ? dishFood.eatenUnit : null;
    let eatenQuantityFromPercentElementString;
    if (dishFood.eatenUnit === '%') {
      if (+this.state.calculatedEatenGrams != undefined && +this.state.calculatedEatenGrams > 0) {
        eatenQuantityFromPercentElementString = `= ${+this.state.calculatedEatenGrams}g`
      } else if (+this.state.calculatedEatenMililiters != undefined && +this.state.calculatedEatenMililiters > 0) {
        eatenQuantityFromPercentElementString = `= ${+this.state.calculatedEatenMililiters}ml`
      }
    }
    let eatenQuantityFromPercentElement = <span className="mt-2 ml-2 font-weight-bold calculated-weight-volume">
      {eatenQuantityFromPercentElementString}
    </span>
    return (<div className="segment-quantification">
      <div className="form-group">
        <label>Eaten unit</label>
        <div className="btn-group btn-group-toggle" data-toggle="buttons">
          {allowedUnits.map((unit) => (
            <label
              key={`unit-${unit}-${dishFood.componentGuid}`}
              className={`btn btn-secondary${ dishFood.eatenUnit === unit ? ' active' : '' }`}
              onClick={()=>this.props.onDishFoodAmtChanged(dishFoodIdx, { eatenUnit: unit })}
            >
              <input
                type="radio"
                name={this.fieldName('eaten_unit')}
                value={unit}
                checked={dishFood.eatenUnit === unit}
                readOnly
              />
              {unit}
            </label>
          ))}
          {hiddenUnit == null ? null :
            <input
              type="hidden"
              name={this.fieldName('eaten_unit')}
              value={hiddenUnit}
              checked={true}
              readOnly
            />
          }
        </div>
      </div>
      <div className="form-group">
        <label>Eaten amount{this.renderHelpBox('eaten')}</label>
        <div className="d-flex justify-content-start">
          <div className="input-group">
            <input
              ref="eatenQuantityInput"
              name={this.fieldName('eaten_quantity')}
              lang="en"
              type="text"
              inputMode="numeric"
              autoComplete="off"
              value={this.state.eatenQuantity}
              className="form-control number-safe"
              placeholder=""
              aria-label=""
              aria-describedby="basic-addon"
              onChange={this.onEatenQuantityChange}
              onKeyUp={this.onEatenQuantityChange}
            />
            <div className="input-group-append">
              <span className="input-group-text">{dishFood.eatenUnit || '?'}</span>
            </div>
          </div>
          {eatenQuantityFromPercentElement}
        </div>
      </div>
    </div>);
  }

  renderIgnoreEnergyValidationButton() {
    return (
      <div className="form-group">
        <input
          type="hidden"
          name={this.fieldName('with_food_energy_limits_validator')}
          value={this.state.withFoodEnergyLimitsValidator}
        />
        <a
          onClick={this.onIgnoreEnergyValidation}
          className={`btn btn-secondary btn-sm ${this.state.withFoodEnergyLimitsValidator === '1' ? '' : 'd-none'}`}>
          <i className="fas fa-times color-notice-error"></i> Skip energy validation
        </a>
      </div>
    );
  }

  render() {
    const { dishFood, dishFoodIdx } = this.props;
    const displayProps = {
      'data-dishfood-idx': dishFoodIdx,
    };
    let canAddSegment = false;
    if (this.props.selectedDishFoodIdx === dishFoodIdx) {
      canAddSegment = this.props.selectedMediaId != null && dishFood.segments.findIndex(x => x.mediaId === this.props.selectedMediaId) === -1
    } else {
      displayProps.style = { display: 'none' }
    }
    const extRes = this.state.foodId === (this.state.extraFood && this.state.extraFood.id) && dishFood.foodExternalResource
      ? dishFood.foodExternalResource
      : null;
    return (
      <div className="segmentation-segment" ref="self" {...displayProps}>
        <input
          type="hidden"
          name={this.fieldName('id')}
          value={dishFood.id}
        />
        <div className="segment-title">
          <div>
            <div className="dot bg-primary"></div>
            <span>{`Dish Food ${(dishFoodIdx + 1).toString().padStart(2, '0')}`}</span>
          </div>
          <div>
            <span
              ref="ttspan_deleteDishFood"
              title="Delete Dish Food"
              style={{cursor: 'pointer'}}
              onClick={evt => this.props.onDishFoodDeleted(dishFoodIdx)}
            >
              <img src={RubyVars.iconUrls.delete} />
            </span>
          </div>
        </div>
        <hr className="mb-0"/>
        <div className="dishfood-container">
          <div className="dishfood-inner-container">
            <div className="source-and-food-selector d-flex justify-content-between">
              <img
                className="icon-dishfood-source"
                src={RubyVars.iconUrls[`logo_${this.state.foodSource}`]}
                onClick={this.onFoodSourceChanged}
              />
              {this.renderFoodSelectionFields()}
            </div>
            {!extRes || this.state.foodSource !== 'mfr' ? null :
              <div style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}>
                <span>
                  <a href={`/foods/${this.state.foodId}`} target="_blank">
                    <i className="fas fa-book-open small mr-1"></i>Show
                  </a>
                  {RubyVars.canEditFood ?
                  <a href={`/foods/${this.state.foodId}/edit`} target="_blank">
                    <i className="fas fa-edit small ml-3 mr-1"></i>Edit
                  </a>
                  : null}
                </span>
                <span dangerouslySetInnerHTML={{__html: extRes}} />
              </div>
            }
            <br />
            {this.renderPresentAmountFields()}
            {this.renderEatenAmountFields()}
            {this.renderIgnoreEnergyValidationButton()}
          </div>
          {dishFood.segments.map((segment, segmentIdx) => {
            return (<DishFoodMediaSegmentor_SingleSegmentFields
              key={`single-dishfood-segment-fields-${dishFood.componentGuid}-${segment.componentGuid}`}
              dishFood={dishFood}
              dishFoodIdx={dishFoodIdx}
              segment={segment}
              segmentIdx={segmentIdx}
              selectedSegmentIdx={this.props.selectedDishFoodSegmentIdx}
              selectedMediaId={this.props.selectedMediaId}
              onSegmentRedraw={this.props.onSegmentRedraw}
              onSegmentDeleted={this.props.onSegmentDeleted}
            />)
          })}
          {canAddSegment ? (
            <React.Fragment>
              <hr className="mt-0" />
              <div className="form-group">
                <label
                  style={{
                    cursor: 'pointer',
                    userSelect: 'none',
                  }}
                  onClick={()=>this.props.onSegmentAdded(this.props.dishFoodIdx)}
                >+ Add a segment</label>
              </div>
            </React.Fragment>
          ) : null}
        </div>
      </div>
    );
  }

  onFoodSourceChanged(evt) {
    const oldIdx = this.foodSources.indexOf(this.state.foodSource);
    const newIdx = (oldIdx + 1) % this.foodSources.length;
    $(this.refs.nameSelect).select2('destroy');
    this.setState({
      foodSource: this.foodSources[newIdx],
    });
  }

  fieldName(name) {
    return `ddsg[dish_foods_attributes][${this.props.dishFoodIdx}][${name}]`;
  }

  setupFoodSelection() {
    const _this = this;
    const selFoodName = $(this.refs.nameSelect);
    selFoodName.on('change', (evt) => {
      const opt = evt.target.selectedOptions[0];
      this.props.onDishFoodFoodChanged(this.props.dishFoodIdx, {
        foodId: +opt.value,
        foodDisplayNameHtml: opt.text,
        food: this.state.annotatableFoods.filter(x => x.id === +opt.value)[0],
        calculatedEatenGrams: this.calculateEatenGrams,
        calculatedEatenMililiters: this.calculateEatenMililiters
      });
      this.setState({
        foodId: +opt.value,
        foodDisplayNameHtml: opt.text
      })
    });
    selFoodName.tlOnce().select2({
      language: RubyVars.I18nLocale,
      ajax: {
        delay: 250,
        data: (params) => {
          const query = {
            filter: {
              display_name: params.term,
            },
            sort_by: 'display_name',
            page: params.page || 1,
            format: 'json'
          }
          if (params.term) {
            query.filter = {
              display_name: params.term,
            }
          } else if (+this.state.foodSetId) {
            query.filter = {
              food_set_id: this.state.foodSetId,
            }
          }
          return query;
        },
        cache: true,
        processResults: function (data, params) {
          let annotatableFoods = _this.state.annotatableFoods;
          data.results.forEach((result) => {
            if (!annotatableFoods.map(x => x.id).includes(result.id)) {
              annotatableFoods.push(result);
            }
          });
          _this.setState({ annotatableFoods });
          // If Food Set name exists and if we're not sending a search term, nest
          // results in a `optgroup` entitled with food set name
          if (_this.state.foodSetId && _this.props.dishFood.foodSetName != null && !params.term) {
            data.results = [{
              text: `Food Set: ${_this.props.dishFood.foodSetName}`,
              children: data.results,
            }]
          };
          return data;
        },
      },
      width: 'copy',
      theme: 'bootstrap',
    });
    // Not sure about the best UX here? Should we keep the food
    // set suggestion here once we selected a food or not?
    // selFoodName.on('select2:select', (e) => {
    //   _this.setState({foodSetId: null});
    // });
  }

  onPresentQuantityChange(event) {
    if (event.type === 'keyup' && event.target.value != '') {
      return
    }
    event.stopPropagation();
    const presentQuantity = event.target.value;
    this.props.onDishFoodAmtChanged(this.props.dishFoodIdx, {
      presentQuantity
    });
  }

  onEatenQuantityChange(event) {
    if (event.type === 'keyup' && event.target.value != '') {
      return
    }
    event.stopPropagation();
    const eatenQuantity = event.target.value;
    this.props.onDishFoodAmtChanged(this.props.dishFoodIdx, {
      eatenQuantity
    });
  }

  onIgnoreEnergyValidation() {
    this.setState({ withFoodEnergyLimitsValidator: '0' })
  }
}

DishFoodMediaSegmentor_SingleDishFoodFields.propTypes = {
  ddsgId: PropTypes.string,
  dishFood: PropTypes.object,
  dishFoodIdx: PropTypes.number,
  selectedDishFoodIdx: PropTypes.number,
  selectedDishFoodSegmentIdx: PropTypes.number,
  selectedMediaId: PropTypes.number,
  selectedDishFoodWithFoodEnergyLimitsValidator: PropTypes.string,
  onDishFoodFoodChanged: PropTypes.func,
  onDishFoodAmtChanged: PropTypes.func,
  onDishFoodDeleted: PropTypes.func,
  onSegmentAdded: PropTypes.func,
  onSegmentRedraw: PropTypes.func,
  onSegmentDeleted: PropTypes.func,
};

export default DishFoodMediaSegmentor_SingleDishFoodFields;
