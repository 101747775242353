$.fn.datePicker = function() {
  $(this).each(function() {
    // instantiate
    const $el = $(this);
    const opts = {
      locale: RubyVars.I18nLocale,
      monthSelectorType: "static",
      minuteIncrement: 1,
      time_24hr: !App.dateUtil.localePrefersMeridiem(),
    };
    const data = $el.data();
    Object.keys(data).forEach(function(k) {
      const m = k.match(/^opts([A-Z]\w*)$/);
      if (m == null) { return; }
      const key = m[1].replace(/^./, c => c.toLowerCase());
      opts[key] = data[k];
    });
    $el.flatpickr(opts);
    const fp = this._flatpickr;

    // workaround local/UTC issues: create a hidden input that has UTC time
    const $hidden = $(`<input type="hidden" name="${$el.attr('name')}" value="${opts.defaultDate}">`);
    fp.config.onChange.push(function(dates) {
      if (dates[0] == null) {
        $hidden.val('');
      } else {
        $hidden.val(moment(dates[0]).toISOString())
      }
    });
    $el.after($hidden)
  });
};
