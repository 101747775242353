import React from "react";
import PropTypes from "prop-types";

class DishFoodMediaSegmentor_DishFoodSegmentList extends React.Component {
  renderListEmpty() {
    return 'None';
  }

  renderDishFoodWithNoSegment(dfIdx) {
    const df = this.props.dishFoods[dfIdx];
    const dfSelected = this.props.selectedDishFoodIdx === dfIdx;
    return (
      <li
        key={`DishFoodAndSegmentListItem-${df.componentGuid}`}
        onClick={(evt) => { evt.stopPropagation(); this.props.onDishFoodAndSegmentSelectionChanged(dfIdx, -1)}}
        style={{cursor: 'pointer'}}
        className={dfSelected ? 'selected' : ''}
      >
        <span className="food-name">
          { df.foodDisplayNameHtml }
        </span>
      </li>
    )
  }
  renderDishFoodWithSingleSegment(dfIdx) {
    const df = this.props.dishFoods[dfIdx];
    const dfSelected = this.props.selectedDishFoodIdx === dfIdx;
    const segment = df.segments[0];
    const otherMedia = this.props.selectedMediaIdx === -1 ? false : segment.mediaId !== this.props.media[this.props.selectedMediaIdx].id;
    return (
      <li
        key={`DishFoodAndSegmentListItem-${df.componentGuid}`}
        onClick={(evt) => { evt.stopPropagation(); this.props.onDishFoodAndSegmentSelectionChanged(dfIdx, 0)}}
        style={{cursor: 'pointer'}}
        className={`${dfSelected ? 'selected' : ''}${otherMedia ? ' other-media' : ''}`}
      >
        <span
          className="food-color"
          style={{
            display: 'inline-block',
            marginRight: '0.5em',
            width: '1em',
            height: '1em',
            backgroundColor: this.props.colorFromIndex(segment.colorIdx),
          }}
        />
        <span className="food-name">
          { df.foodDisplayNameHtml }
        </span>
      </li>
    );
  }
  renderDishFoodWithManySegments(dfIdx) {
    const df = this.props.dishFoods[dfIdx];
    const dfSelected = this.props.selectedDishFoodIdx === dfIdx;
    return (
      <li
        key={`DishFoodAndSegmentListItem-${df.componentGuid}`}
        onClick={(evt) => { evt.stopPropagation(); this.props.onDishFoodAndSegmentSelectionChanged(dfIdx, -1)}}
        style={{cursor: 'pointer'}}
        className={dfSelected ? 'selected' : ''}
      >
        <span className="food-name">
          { df.foodDisplayNameHtml }
        </span>
        <ol>
          {df.segments.map((segment, segmentIdx) => {
            const otherMedia = this.props.selectedMediaIdx === -1 ? false : segment.mediaId !== this.props.media[this.props.selectedMediaIdx].id;
            return (<li
              key={`DishFood-${df.componentGuid}-SegmentListItem-${segment.componentGuid}`}
              onClick={(evt) => { evt.stopPropagation(); this.props.onDishFoodAndSegmentSelectionChanged(dfIdx, segmentIdx)}}
              style={{cursor: 'pointer'}}
              className={`${dfSelected && segmentIdx === this.props.selectedDishFoodSegmentIdx ? 'selected' : ''}${otherMedia ? ' other-media' : ''}`}
            >
              <span
                className="food-color"
                style={{
                  display: 'inline-block',
                  width: '1em',
                  height: '1em',
                  backgroundColor: this.props.colorFromIndex(segment.colorIdx),
                }}
              />
              Segment {segmentIdx + 1}
            </li>);
          })}
        </ol>
      </li>
    );
  }

  renderListItems() {
    return this.props.dishFoods.map((dishFood, dishFoodIdx) => {
      switch (dishFood.segments.length) {
        case 0:
        return this.renderDishFoodWithNoSegment(dishFoodIdx);
        case 1:
        return this.renderDishFoodWithSingleSegment(dishFoodIdx);
      }
      return this.renderDishFoodWithManySegments(dishFoodIdx);
    });
  }

  renderTotalGramsAndMililiters() {
    let totalGrams = 0;
    let totalMililiters = 0;
    this.props.dishFoods.forEach((df) => {
      totalGrams = totalGrams + (+df.calculatedEatenGrams || 0)
      totalMililiters = totalMililiters + (+df.calculatedEatenMililiters || 0)
    })

    let totalString = `Total: ${totalGrams}g | ${totalMililiters}ml`;
    return <React.Fragment>
      <hr className="m-0" />
      <p className="mt-1 mb-1 font-weight-bold total-weight-volume">{totalString}</p>
    </React.Fragment>
  }

  render() {
    return (
      <div
        className="segmentation-list"
        onClick={(evt) => { evt.stopPropagation(); this.props.onDishFoodAndSegmentSelectionChanged(-1, -1) }}
      >
        <div className="list-title">
          <span>Dish Foods</span>
        </div>
        <hr className="mb-0" />
        <ol className="items-container">
          { this.props.dishFoods.length > 0
              ? this.renderListItems()
              : this.renderListEmpty()
          }
        </ol>
        {this.renderTotalGramsAndMililiters()}
        <hr className="mt-0" />
        <div className="form-group">
          <label
            style={{
              cursor: 'pointer',
              userSelect: 'none',
            }}
            className="add-dish-food-btn"
            onClick={(evt)=>{ evt.stopPropagation(); this.props.onDishFoodAdded(); }}
          >+ Add a dishfood</label>
        </div>
      </div>
    );
  }
}

DishFoodMediaSegmentor_DishFoodSegmentList.propTypes = {
  media: PropTypes.array,
  selectedMediaIdx: PropTypes.number,
  dishFoods: PropTypes.array,
  selectedDishFoodIdx: PropTypes.number,
  selectedDishFoodSegmentIdx: PropTypes.number,
  colorFromIndex: PropTypes.func,
  onDishFoodAndSegmentSelectionChanged: PropTypes.func,
  onDishFoodAdded: PropTypes.func,
};

export default DishFoodMediaSegmentor_DishFoodSegmentList;
