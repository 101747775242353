import {
  convertToNumber,
  projection,
  calculateDataToDaily,
  toTimePeriodReducerFn,
  toTimePeriodDefinerFn,
  isOneDay
} from './helpers'

import jQuery from 'jquery'
import { frTimeLocale, deTimeLocale } from './locales'
import * as d3 from "d3";

export default function initCalendarHeatmap() {
  var holder = jQuery('#calendar-heatmap');

  if (holder.length) {
    var src = holder.data('src');
    var locale = holder.data('locale');

    if(locale === "fr") {
      d3.timeFormatDefaultLocale(frTimeLocale);
    } else if(locale === "de") {
      d3.timeFormatDefaultLocale(deTimeLocale);
    }

    if (src) {
      d3.csv(src).then(function(data) {
        var md = {
          'date': ['x'],
          'time': ['y'],
          'somme': ['value', convertToNumber],
        };
        var chartData = projection(md)(data);

        chartData = calculateDataToDaily(
          chartData,
          toTimePeriodReducerFn,
          toTimePeriodDefinerFn,
          isOneDay,
          'x', 'x'
        );

        ResponsiveHelper.addRange({
          '768..': {
            on: function() {
              holder.calendarHeatmap({
                data: chartData,
              });
            },
            off: function() {
              holder.calendarHeatmap('destroy');
            }
          },
          '..767': {
            on: function() {
              holder.calendarHeatmap({
                data: chartData,
                dateFormat: '%d/%m/%y', //'Nov 25'
                barsContainerPadding: 10,
                leftPadding: 40,
                yAxisLeftPadding: 0,
                lineHeight: 15,

                barWidth: 10,
                barHeight: 10,
              });
            },
            off: function() {
              holder.calendarHeatmap('destroy');
            }
          }
        });
      });
    } else {
      holder.addClass(errorClass);
    }
  }
}

/*
 * jQuery Calendar Heatmap plugin
 */
;(function($) {
  'use strict';

  function CalendarHeatmap(opt) {
    this.holder = $(opt.holder);
    this.options = $.extend({
      data: {},
      svgHolder: '.svg-holder',
      labelList: '.label-list',

      barsContainerPadding: 10,
      barWidth: 17,
      barHeight: 17,
      barMargin: 2,
      barsYCount: 24,
      valStep: 500,

      dateFormat: '%a %d/%m/%y', // https://github.com/d3/d3-time-format#locale_format
      hourFormat: '%H',
      leftPadding: 48,
      yAxisLeftPadding: 0,
      lineHeight: 18,
      sepHeight: 13,

      colorToScale: ['#bfd9ab', '#5aa323'],
    }, opt);

    this.init();
  }

  CalendarHeatmap.prototype = {
    init: function() {
      if (this.options.data.length === 0) {
        return;
      }

      this.data = {};
      this.visibleData = [];

      this.prevIndex = 0;
      this.visibleDataStartIndex = 0;
      this.daysCount = this.getDaysCount(
        new Date(this.options.data[0].x),
        new Date(this.options.data[this.options.data.length-1].x)
      );
      this.dateFormat = d3.timeFormat(this.options.dateFormat);
      this.dateParser = d3.timeParse(this.options.dateFormat);

      this.hourFormat = d3.timeFormat(this.options.hourFormat);
      this.hourParser = d3.timeParse(this.options.hourFormat);

      this.lineHeight = this.options.lineHeight;
      this.sepHeight = this.options.sepHeight;
      this.sepMargin = 2;
      this.marginBottom = 4;
      this.marginTop = this.lineHeight/2;
      this.leftPadding = this.options.leftPadding;
      this.yAxisLeftPadding = this.options.yAxisLeftPadding;

      this.barsContainerPadding = this.options.barsContainerPadding;
      this.barWidth = this.options.barWidth;
      this.barHeight = this.options.barHeight;
      this.barMargin = this.options.barMargin;
      this.barsYCount = this.options.barsYCount;
      this.barsXCount = 0;
      this.valStep = this.options.valStep;

      this.findElements();
      this.refreshHolderSize();
      this.setActiveData();
      this.attachEvents();
      this.isInited = true;
    },

    findElements: function() {
      this.win = $(window);
      this.svgHolder = this.holder.find(this.options.svgHolder);
      this.labelList = this.holder.find(this.options.labelList);
    },

    attachEvents: function() {
      var self = this;
      this.svgHolder.addClass('no-dragging');

      this.removeClassHandler = function() {
        self.flag = false;
        self.resizeFn();
      };
      this.resizeHandler = function() {
        if(!self.flag) {
          self.flag = true;
        }
        clearTimeout(self.resizeTimer);
        self.resizeTimer = setTimeout(self.removeClassHandler, 500);
      };

      this.resizeFn = function() {
        self.refreshHolderSize();
        self.setActiveData();
      };

      this.startDragFn = function() {
        self.svgHolder.removeClass('no-dragging');
        d3.select(this).classed('dragging', true);
      };

      this.dragFn = function() {
        self.isDragging = true;
        var indexChanged = self.numIndex(d3.event.dx * -1);
        if (indexChanged) {
          self.setActiveData();
        }
      };

      this.endDragFn = function() {
        self.isDragging = false;
        self.svgHolder.addClass('no-dragging');
        d3.select(this).classed('dragging', false);
      };

      this.overFn = function(e) {
        if (!self.isDragging && self.svgHolder.hasClass('no-dragging')) {
          self.svgHolder.removeClass('no-dragging');
        }
      };

      this.outFn = function(e) {
        var curTarget = $(e.currentTarget);
        var relTarget = $(e.relatedTarget);
        if (!relTarget.is(curTarget) && !relTarget.closest(curTarget).length) {
          self.svgHolder.addClass('no-dragging');
        }
      };

      this.win.on('resize orientationchange', this.resizeHandler);

      this.svgHolder.on('touchstart mouseover', this.overFn);
      this.svgHolder.on('touchend mouseout', this.outFn);

      d3.select(this.svgHolder[0]).call(
        d3.drag()
          .on('start', this.startDragFn)
          .on('drag', this.throttle(this.dragFn, 70))
          .on('end', this.endDragFn)
      );
    },

    buildGraph: function() {
      this.svg = d3
        .select(this.svgHolder[0])
        .append('svg');

      // x axis
      this.xGroup = this.svg.append('g')
        .attr('class', 'x-group')

      this.xGroups = this.xGroup
        .selectAll('text')
        .data(this.xAxisValues)
        .enter()
        .append('g');

      this.xGroups
        .append('text')
        .attr('text-anchor', 'middle')
        .text((d) => d);

      this.xGroups
        .append('rect')
        .attr('width', '1')
        .attr('height', this.sepHeight)
        .attr('y', -1 * (this.sepMargin + this.sepHeight + this.lineHeight));

      // y axis
      this.yGroup = this.svg.append('g')
        .attr('class', 'y-group');

      this.yGroup
        .selectAll('g')
        .data(this.yAxisValues)
        .enter().append('g')
        .append('text')
        .attr('alignment-baseline', 'middle')
        .text((d) => d + ':00');

      //bars
      this.barGroup = this.svg.append('g')
        .attr('class', 'bars-group');

      this.barGroups = this.barGroup
        .selectAll('g')
        .data(this.gridData)
        .enter()
        .append('g')
        .attr('class', 'grid-group');

      this.barSubGroups = this.barGroups
        .selectAll('g')
        .data((d) => d.hours)
        .join('g');

      this.barRect = this.barSubGroups
        .append('rect');

      // value bars
      this.valBarGroup = this.svg.append('g')
        .attr('class', 'value-bars-group');

      this.valBarDaysGroups = this.valBarGroup
        .selectAll('g')
        .data(this.visibleData)
        .enter()
        .append('g')
        .attr('class', 'value-grid-day-group');

      this.valBarHoursGroups = this.valBarDaysGroups
        .selectAll('g')
        .data((d) => d.data)
        .enter()
        .append('g')
        .attr('class', 'value-grid-hour-group')
        .attr('data-day', (d) => d.x)
        .attr('data-hour', (d) => d.y);

      this.valBarRect = this.valBarHoursGroups
        .append('rect');
    },

    buildLabels: function() {
      var self = this;
      if (this.levelStepRanges.length) {
        this.labelList.html('');
        this.levelStepRanges.forEach(function(el) {
          var firstNum = el[0];
          var lastNum = el[1];
          var text = firstNum + '-' + lastNum + ' (kcal)';

          $('<li>').appendTo(self.labelList)
            .append(
              $('<span class="color-box">')
                .css('background-color', self.colorScaled(firstNum))
            )
            .append($('<span class="label">').text(text));
        });
      }
    },

    setPositionParams: function() {
      var self = this;

      this.svg
        .attr('width', this.svgHolder.outerWidth())
        .attr('height', this.svgHeight);

      // x axis
      this.xGroup
        .attr('transform', 'translate(0, ' + (this.height) + ')');

      this.xGroups
        .attr('transform', function(d) {
          return 'translate(' + self.xScaled(self.dateParser(d)) + ', 0)'
        });

      // y axis
      this.yGroup
        .attr('transform', 'translate(' + this.yAxisLeftPadding + ', ' + (this.barHeight/2) + ')');

      this.yGroup
        .selectAll('g')
        .attr('transform', function(d) {
          var y = self.yScaled(self.hourParser(d));
          return 'translate(0, ' + y + ')';
        });

      this.barGroups
        .attr('data-date', function(d) {
          return d.date;
        })
        .attr('transform', function(d) {
          var x = self.xScaled(self.dateParser(d.date)) - (self.barWidth / 2);
          
          return 'translate(' + x + ', 0)';
        });

      this.barSubGroups
        .attr('transform', function(d) {
          var y = self.yScaled(self.hourParser(d));

          return 'translate(0, ' + y + ')';
        });

      this.barRect
        .attr('width', this.barWidth)
        .attr('height', this.barHeight);

      this.valBarHoursGroups
        .attr('transform', function(d) {
          var x = self.xScaled(self.dateParser(self.dateFormat(new Date(d.x)))) - (self.barWidth / 2);
          var y = (self.yScaled(self.hourParser(d.y)));

          return 'translate(' + x + ', ' + y + ')';
        });

      this.valBarRect
        .attr('fill', function(d) {
          return self.colorScaled(d.value);
        })
        .attr('width', this.barWidth)
        .attr('height', this.barHeight);
    },

    setDataPart: function(fromIndex) {
      this.visibleData = this.getVisibleData(fromIndex);
    },

    getDaysCount: function(firstDay, lastDay) {
      return d3.timeDay.count(
        d3.timeDay.floor(firstDay),
        d3.timeDay.ceil(lastDay)
      );
    },

    getVisibleData: function(fromIndex) {
      var result = [];

      result = this.data.slice(fromIndex, fromIndex + this.barsXCount-1);

      return result;
    },

    scaleData: function() {
      var self = this;

      this.xScaled = d3.scaleLinear()
        .domain(d3.extent(this.xArrayData).map(function(el){
          return self.dateParser(self.dateFormat(new Date(el)));
        }))
        .range([this.leftPadding + this.barsContainerPadding, this.width - this.barsContainerPadding]);

      this.yScaled = d3.scaleLinear()
        .domain(d3.extent(this.yArrayData).map(function(el){
          return self.hourParser(self.hourFormat(el));
        }))
        .range([this.bottomBound - this.barHeight, 0]);

      var color = d3.scaleLinear()
        .domain([this.labelRanges[0], this.labelRanges[this.labelRanges.length-1]])
        .range(this.options.colorToScale);

      this.colorScaled = function(value) {
        var middleValue = 0;

        self.levelStepRanges.forEach(function(el) {
          var first = el[0];
          var last = el[1];
          if (value >= first && value <= last) {
            middleValue = el[0];
          }
        });
        
        return color(middleValue);
      };
    },

    getAxisValues: function() {
      var self = this;

      var maxVal = d3.max(this.visibleData, function(el) {
        return d3.max(el.data, function(el) {
          return el.value;
        });
      });

      this.labelRanges = d3.range(0, maxVal, this.valStep);
      this.levelStepRanges = this.labelRanges.map(function(el) {
        return [
          el ? el : el + 1,
          el + self.valStep - 1
        ];
      });

      var firstDay = new Date(this.visibleData[0].x);
      var lastDay = d3.timeDay.offset(firstDay, this.barsXCount);
      this.xArrayData = d3.timeDay.range(d3.timeDay.offset(firstDay, -1), lastDay, 1);

      this.xAxisValues = this.xArrayData.reduce(function(acc, el) {
        var day = el.getUTCDay();

        if (day === 0) {
          acc.push(self.toDateFormat(el));
        }

        return acc;
      }, []);

      this.yArrayData = d3.timeHours(d3.timeHour(d3.timeSunday()), d3.timeHour(d3.timeMonday()));
      this.yAxisValues = this.yArrayData.reduce(function(acc, date, i) {
        if (i % 2 === 0) {
          acc.push(self.hourFormat(date));
        }

        return acc;
      }, []);

      this.gridData = this.xArrayData.map(function(date) {
        return {
          date: self.dateFormat(date),
          hours: self.yArrayData.map(function(hour) {
            return self.hourFormat(hour);
          }),
        };
      });
    },

    refreshHolderSize: function() {
      var bottomSpace = this.lineHeight + this.sepHeight + this.sepMargin;
      
      this.width = this.svgHolder.outerWidth();
      this.barsFullWidth = this.width - this.leftPadding - (this.barsContainerPadding * 2);
      this.barsXCount = Math.floor(this.barsFullWidth / (this.barWidth + this.barMargin));

      this.svgHeight = (this.barsYCount * (this.barHeight + this.barMargin)) + bottomSpace + this.marginBottom;
      this.height = this.svgHeight - this.marginBottom;
      this.bottomBound = this.height - bottomSpace;
    },

    toDateFormat: function(date) {
      return this.dateFormat(date);
    },

    setActiveData: function() {
      if (this.isInited) {
        this.destroySvg();
      }

      this.data = this.options.data;

      this.setDataPart(this.visibleDataStartIndex);
      this.getAxisValues();
      this.scaleData();

      this.buildGraph();
      this.setPositionParams();
      this.buildLabels();
    },

    numIndex: function(dx) {
      var from = 0;

      var to = this.options.data.length -1;
      
      // days in data less then bars
      if (this.daysCount - this.barsXCount < 0) {
        return false;
      }
      
      this.prevIndex = this.visibleDataStartIndex;

      if (this.visibleDataStartIndex >= from && this.visibleDataStartIndex <= to) {
        this.visibleDataStartIndex += Math.ceil(dx);
      }

      if (this.visibleDataStartIndex < from) {
        this.visibleDataStartIndex = 0;
      }

      if (this.visibleDataStartIndex > to) {
        this.visibleDataStartIndex = to;
      }
    
      return this.prevIndex !== this.visibleDataStartIndex;
    },

    destroySvg: function() {
      this.svgHolder.html('');
      this.labelList.html('');

      this.lastDay = undefined;

      this.data = {};
      this.visibleData = [];
      this.labelRanges = [];
      this.levelStepRanges = [];
      this.xArrayData = [];
      this.xAxisValues = [];
      this.yArrayData = [];
      this.yAxisValues = [];
      this.gridData = [];

      this.xScaled = function() {};
      this.yScaled = function() {};
    },

    destroy: function() {
      var self = this;
      this.win.off('resize orientationchange', this.resizeHandler);

      d3.select(this.svgHolder[0]).on('.drag', null);

      clearTimeout(this.resizeTimer);
      clearTimeout(this.throttleTimer);

      this.svgHolder.off('touchstart mouseover', this.overFn);
      this.svgHolder.off('touchend mouseout', this.outFn);
      this.svgHolder.removeClass('dragging');
      this.svgHolder.removeClass('no-dragging');

      this.destroySvg();

      this.holder.removeData('graph_api_CalendarHeatmap');

      var keys = Object.keys(this);
      keys.forEach(function(key) {
        var type = typeof self[key];
        if (self.hasOwnProperty(key)) {
          self[key] = (type === 'function' || type === 'object' || type === 'array') ?
            null : undefined;
        }
      });
    },

    throttle: function(func, timeout) {
      var self = this;
      var execute = true;
      return function () {
        var args = [].slice.call(arguments);
        if (!execute) {
          return;
        }
        execute= false;
        func.apply(self, args);
        self.throttleTimer = setTimeout(function() {
          execute = true;
        }, timeout);
      };
    }
  };

  // jQuery plugin interface
  $.fn.calendarHeatmap = function(opt) {
    var args = Array.prototype.slice.call(arguments);
    var method = args[0];

    return this.each(function() {
      var $holder = jQuery(this);
      var instance = $holder.data('graph_api_CalendarHeatmap');

      if (typeof opt === 'object' || typeof opt === 'undefined') {
        $holder.data('graph_api_CalendarHeatmap', new CalendarHeatmap($.extend({
          holder: this
        }, opt)));
      } else if (typeof method === 'string' && instance) {
        if (typeof instance[method] === 'function') {
          args.shift();
          instance[method].apply(instance, args);
        }
      }
    });
  };
}(jQuery));
