$(document).on('turbolinks:load', () => {
  const noteModals = document.getElementsByClassName('note-modal');
  for(let modal of noteModals) {
    const modalForm = modal.getElementsByTagName('form')[0];
    const noteMaxLength = modal.dataset['noteMaxLength'];
    const noteDomId = modal.dataset['noteDomId'];
    const textarea = modal.getElementsByTagName('textarea')[0];
    const remainingLengthElement = modal.getElementsByClassName('note-length')[0];
    const errorElement = modalForm.getElementsByClassName('note-error')[0];

    textarea.addEventListener("input", event => {
      const text = event.currentTarget.value;
      let currentLength = text.length;
      if (currentLength) {
        // Treat the value as a sequence of unicode code points.
        // Allows for the proper count of emoji chars.
        currentLength = text.match(/./gu).length;
      }
      const remainingLength = noteMaxLength - currentLength;

      errorElement.innerHTML = null;

      if (remainingLength >= 0) {
        remainingLengthElement.classList.remove('text-danger');
        remainingLengthElement.classList.add('text-success');
      } else {
        remainingLengthElement.classList.remove('text-success');
        remainingLengthElement.classList.add('text-danger');
      }

      remainingLengthElement.innerHTML = remainingLength;
    })

    modalForm.addEventListener('ajax:success', evt => {
      const [data, _status, _xhr] = evt.detail;
      const noteElement = document.getElementById(noteDomId);
      noteElement.innerHTML = data.sanitized_note;
      $(modal).modal('hide');
    });
    modalForm.addEventListener('ajax:error', evt => {
      const [_data, _status, xhr] = evt.detail;
      try {
        errorElement.innerHTML = `<p class='mt-2 mb-0'>${JSON.parse(xhr.responseText)}</p>`;
      } catch (e) {
        alert(`Unknown error.\n\nStatus: ${xhr.statusText} (${xhr.status})`);
      }
    });
  }
});
