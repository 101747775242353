window.App = window.App || {};
App.Util || (App.Util = {
  hashCode: function(str) {
    if (typeof str === 'string' || str instanceof String) {
      const arr = str.split('');
      const num = arr.reduce(
        (prevHash, currVal) => (
          ((prevHash << 5) - prevHash) + currVal.charCodeAt(0)
        ),
        0
      );
      return num >>> 0;
    } else {
      return 0;
    }
  },
  fnCallerStr: function(depth = 0) {
    const stack = (new Error).stack;
    if (stack != null) {
      return stack.split(/\s*\n\s*/)[depth+3];
    } else {
      // Error stack unsupported, so we just use a random identifier
      return Math.random().toString(36).substr(2);
    }
  },
  fnCallerId: function() {
    return (this.hashCode(this.fnCallerStr(1))).toString(16);
  }
});
