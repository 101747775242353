import {
  calculateDataToDaily,
  isOneDay,
  convertToNumber,
  projection,
  sortByDate,
  completeMissingData
} from './helpers';

import jQuery from 'jquery'
import { frTimeLocale, deTimeLocale } from './locales';
import * as d3 from "d3";

export default function initStackedHistogram() {
  var holder = jQuery('#stacked-histogram');

  if (holder.length) {
    var src1 = holder.data('src');
    var src2 = holder.data('src2');
    var locale = holder.data('locale');
    var i18n = holder.data('i18n');
    var dataFirst = null;

    if(locale === "fr") {
      d3.timeFormatDefaultLocale(frTimeLocale);
    } else if(locale === "de") {
      d3.timeFormatDefaultLocale(deTimeLocale);
    }

    if (src1 && src2 && locale) {
      d3.csv(src1).then(function(data) {
        dataFirst = data;

        return d3.csv(src2);
      }).then(function(dataSecond) {
        var md = {
          'date': ['x'],
          'y': ['y'],
        };

        // add nutrition values to one object
        var reducerFn = function(cur, acc, el, groupKey) {
          if (el === groupKey) {
            var type = cur[el];
            var typeVal = cur.percentage;

            acc[acc.length - 1][type] = convertToNumber(typeVal);
          }
        };

        // prepare object for reducing
        var definerFn = function (accObj, curObj, keys, groupKey) {
          var obj = {};

          keys.forEach(function(el) {
            if (el === groupKey || el === 'date') {
              var propName = el === groupKey ? curObj[el] : el;
              var value = el === groupKey ? convertToNumber(curObj.percentage) : curObj[el];

              Object.defineProperty(obj, propName, {
                value: value,
                writable: true,
                enumerable: true,
                configurable: true,
              });
            }
          });

          accObj.push(obj);
        };

        // put together 'y' props handler
        var modifyObj = function(obj) {
          var keys = Object.keys(obj);
          obj.y = {};

          keys.forEach(function(key) {
            if (key !== 'date') {
              obj.y[key] = obj[key] * 100;
            }
          });

          return obj;
        };

        var calculatedDataFirst = calculateDataToDaily(
          dataFirst,
          reducerFn,
          definerFn,
          isOneDay,
          'type'
        ).map(modifyObj);
        var calculatedDataSecond = calculateDataToDaily(
          dataSecond,
          reducerFn,
          definerFn,
          isOneDay,
          'food_group_modified'
        ).map(modifyObj);

        var dataset = {
          'macronutrients': {
            data: projection(md)(calculatedDataFirst).sort(sortByDate),
            xTitle: '',
            yTitle: i18n.labels.weight,
            sortArr: [],
            stackOrder: d3.stackOrderNone,
          },
          'food_groups': {
            data: projection(md)(calculatedDataSecond).sort(sortByDate),
            xTitle: '',
            yTitle: i18n.labels.weight,
            sortArr: [
              'vegetables_fruits',
              'grains_potatoes_pulses',
              'dairy_products_meat_fish_eggs_tofu',
              'oils_fats_nuts',
              'sweets_salty_snacks_alcohol',
              'unclassified'
            ],
            stackOrder: d3.stackOrderReverse,
          },
          'locale': locale
        };

        ResponsiveHelper.addRange({
          '768..': {
            on: function() {
              holder.stackedHistogram({
                data: dataset,
                i18n: i18n
              });
            },
            off: function() {
              holder.stackedHistogram('destroy');
            }
          },
          '..767': {
            on: function() {
              holder.stackedHistogram({
                data: dataset,
                i18n: i18n,
                barsCount: 15,
                dateFormat: '%d/%m/%y', //'25/11/2021'
                barsContainerPadding: 10,
                leftPadding: 40,
                yAxisLeftPadding: 0,
                barsMarginLeft: 5,
                lineHeight: 15,
              });
            },
            off: function() {
              holder.stackedHistogram('destroy');
            }
          }
        });
      });
    } else {
      holder.addClass(errorClass);
    }
  }
}

/*
 * jQuery Stacked Histogram plugin
 */
;(function($) {
  'use strict';

  function StackedHistogram(opt) {
    this.holder = $(opt.holder);
    this.i18n = opt.i18n;
    this.options = $.extend({
      data: {},
      svgHolder: '.svg-holder',
      filterBtn: '.graph-switcher .opener',
      labelBtn: '.graph-switcher .slide [data-value]',
      labelTitle: '.label-title',
      labelList: '.label-list',

      barsCount: 20,
      dateFormat: '%a %d/%m/%y', // https://github.com/d3/d3-time-format#locale_format
      barsContainerPadding: 30,
      barsMarginLeft: 8,
      leftPadding: 68,
      yAxisLeftPadding: 30,
      lineHeight: 18,
      sepHeight: 13,
    }, opt);

    this.init();
  }

  StackedHistogram.prototype = {
    init: function() {
      this.data = {};
      this.visibleData = [];
      this.visibleDataStartIndex = 0;

      this.dateFormat = d3.timeFormat(this.options.dateFormat);
      this.dateParser = d3.timeParse(this.options.dateFormat);

      this.lineHeight = this.options.lineHeight;
      this.sepHeight = this.options.sepHeight;
      this.sepMargin = 2;
      this.marginBottom = 4;
      this.marginTop = this.lineHeight/2;
      this.leftPadding = this.options.leftPadding;
      this.yAxisLeftPadding = this.options.yAxisLeftPadding;

      this.barsMarginLeft = this.options.barsMarginLeft;
      this.barsContainerPadding = this.options.barsContainerPadding;

      this.findElements();
      this.refreshHolderSize();
      this.setActiveData();
      this.attachEvents();
      this.isInited = true;
    },

    findElements: function() {
      this.win = $(window);
      this.svgHolder = this.holder.find(this.options.svgHolder);
      this.filter = this.holder.find(this.options.filterBtn);
      this.labelTitle = this.holder.find(this.options.labelTitle);
      this.labelList = this.holder.find(this.options.labelList);
    },

    attachEvents: function() {
      var self = this;
      this.svgHolder.addClass('no-dragging');

      this.removeClassHandler = function() {
        self.flag = false;
        self.resizeFn();
      };

      this.resizeHandler = function() {
        if (!self.flag) {
          self.flag = true;
        }
        clearTimeout(self.resizeTimer);
        self.resizeTimer = setTimeout(self.removeClassHandler, 500);
      };

      this.resizeFn = function() {
        this.refreshHolderSize();
        this.scaleData();
        this.setPositionParams();
      };

      this.startDragFn = function() {
        self.svgHolder.removeClass('no-dragging');
        d3.select(this).classed('dragging', true);
      };

      this.dragFn = function() {
        if (self.options.barsCount >= self.data.data.length) {
          return;
        }
        self.isDragging = true;
        var indexChanged = self.numIndex(d3.event.dx * -1);

        if (indexChanged) {
          self.setActiveData();
        }
      };

      this.endDragFn = function() {
        self.isDragging = false;
        self.svgHolder.addClass('no-dragging');
        d3.select(this).classed('dragging', false);
      };

      this.overFn = function(e) {
        if (!self.isDragging && self.svgHolder.hasClass('no-dragging')) {
          self.svgHolder.removeClass('no-dragging');
        }
      };

      this.outFn = function(e) {
        var curTarget = $(e.currentTarget);
        var relTarget = $(e.relatedTarget);
        if (!relTarget.is(curTarget) && !relTarget.closest(curTarget).length) {
          self.svgHolder.addClass('no-dragging');
        }
      };

      this.win.on('resize orientationchange', this.resizeHandler);

      this.svgHolder.on('touchstart mouseover', this.overFn);
      this.svgHolder.on('touchend mouseout', this.outFn);

      d3.select(this.svgHolder[0]).call(
        d3.drag()
          .on('start', this.startDragFn)
          .on('drag', this.throttle(this.dragFn, 70))
          .on('end', this.endDragFn)
      );
    },

    buildGraph: function() {
      this.svg = d3.select(this.svgHolder[0])
        .append('svg');

      // x axis
      this.xGroup = this.svg.append('g')
        .attr('class', 'x-group')

      this.xGroups = this.xGroup.selectAll('text')
          .data(this.xAxisValues)
        .enter().append('g');
      this.xGroups
        .append('text')
        .attr('text-anchor', 'middle')
        .text(function(d) { return d; });

      this.xGroups
        .append('rect')
        .attr('width', '1')
        .attr('height', '13')
        .attr('y', '-33');

      // y axis
      this.yGroup = this.svg.append('g')
        .attr('class', 'y-group');

      this.yGroup.selectAll('g')
        .data(this.yAxisValues)
      .enter().append('g')
      .append('text')
      .attr('alignment-baseline', 'middle')
      .text(function(d) {
        return d ? d + '%' : d;
      });

      // y title
      this.titleY = this.svg.append('text')
        .attr('class', 'title-y')
        .text(this.data.yTitle)
        .attr('text-anchor', 'middle');

      // horizontal lines
      this.horizLines = this.svg.append('g')
        .attr('class', 'lines-group')
        .selectAll('line')
          .data(this.yAxisValues)
        .enter().append('line');

      //bars
      this.barGroup = this.svg.append('g')
        .attr('class', 'bars-group');

      this.barGroups = this.barGroup
        .selectAll('g')
          .data(this.yArrayData)
        .enter()
        .append('g')
        .attr('data-group-name', function(d) {
          return d.key.replace(/\s*/gm, '');
        });

      this.barSubGroups = this.barGroups
        .selectAll('g')
          .data(function(d) { return d; })
        .join('g');

      this.barRect = this.barSubGroups
        .append('rect');
    },

    buildLabels: function(key) {
      if (key) {
        var self = this;
        var btn = this.holder.find(this.options.labelBtn + '[data-value="' + key + '"]');

        if (btn.length) {
          var label = btn[0].dataset.label;

          if (label) {
            this.labelTitle.html(label + ': ');
          }

          if (this.yKeys.length) {

            this.labelList.html('');
            this.yKeys.forEach(function(group_key) {
              $('<li>')
                .attr('data-group-name', group_key)
                .appendTo(self.labelList)
                .append($('<span class="color-box">'))
                .append($('<span class="label">').text(self.i18n[key][group_key]));
            });
          }
        }
      }
    },

    setPositionParams: function() {
      var self = this;
      var bottomBoundCentered = this.bottomBound;

      var barWidth = (this.barsFullWidth - ((this.daysCount-1) * this.barsMarginLeft)) /
              this.daysCount;

      if (barWidth > 30) { barWidth = 30; } // MFR edit: usable massive datasets

      this.svg
        .attr('width', this.svgHolder.outerWidth())
        .attr('height', this.svgHolder.outerHeight());

      // x axis
      this.xGroup
        .attr('transform', 'translate(0, ' + (this.height) + ')');

      this.xGroups
        .attr('transform', function(d) {
          return 'translate(' + self.xScaled(self.dateParser(d)) + ', 0)'
        });

      // y axis
      this.yGroup
        .attr('transform', 'translate(' + this.yAxisLeftPadding + ', 0)');

      this.yGroup
        .selectAll('g')
        .attr('transform', function(d) {
          return 'translate(0, ' + (bottomBoundCentered - self.yScaled(d)) + ')';
        });

      // y title
      this.titleY
        .attr('transform', 'translate(' + (this.lineHeight - 2) + ', ' + bottomBoundCentered/2 + ') rotate(-90 0 0)')

      // horizontal lines
      this.horizLines
        .attr('x1', this.leftPadding)
        .attr('y1', bottomBoundCentered)
        .attr('x2', this.width)
        .attr('y2', bottomBoundCentered)
        .attr('transform', function(d) {
          return 'translate(0, -' + (bottomBoundCentered - self.lineHeight - self.yScaled(d)) + ')';
        });

      //bars
      this.barGroup
        .attr('transform', 'translate(0, ' + (this.lineHeight) + ')');

      this.barSubGroups
        .attr('transform', function(d) {
          var x = self.xScaled(self.dateParser(self.toDateFormat(new Date(d.data.date)))) - barWidth/2;
          var y = self.yScaled(d[0]);
          return 'translate(' + x + ', ' + y + ')';
        });

      this.barRect
        .attr('width', barWidth)
        .attr('height', function(d) {
          return self.yScaled(d[1] - d[0]);
        });
    },

    setDataPart: function(fromIndex) {
      this.visibleData = this.getVisibleData(fromIndex);
    },

    getVisibleData: function(fromIndex) {
      var result = [];
      result = this.data.data.slice(fromIndex, fromIndex + this.options.barsCount);

      if (result.length === 0) { return []; } // MFR edit: prevent crash

      var first = result[0].x;
      var last = result[result.length-1].x

      this.daysCount = d3.timeDay.count(
        d3.timeDay.floor(new Date(first)),
        d3.timeDay.ceil(new Date(last))
      );

      return result;
    },

    scaleData: function() {
      var self = this;

      this.xScaled = d3.scaleLinear()
        .domain(d3.extent(this.xArrayData).map(function(el){
          return self.dateParser(self.dateFormat(new Date(el)));
        }))
        .range([this.leftPadding + this.barsContainerPadding, this.width - this.barsContainerPadding]);

      this.yScaled = d3.scaleLinear()
        .domain(d3.extent(this.yAxisValues))
        .range([0, this.bottomBound - this.lineHeight]);
    },

    getAxisValues: function() {
      var self = this;

      this.xArrayData = this.visibleData.map(function(el) {
        return el.x;
      });

      this.yKeys = this.visibleData.reduce(function(acc, cur){
        var keys = Object.keys(cur.y);

        if(keys.includes(self.i18n.food_groups.unclassified)) {
          keys = self.i18n.food_groups.ordered_food_keys;
        }

        keys.forEach(function(key) {
          if (acc.indexOf(key) === -1 && key !== 'date') {
            acc.push(key);
          }
        });
        return acc;
      }, []);

      var sortArr = this.data.sortArr;

      if (sortArr.length) {
        this.yKeys.sort(function(a, b) {
          return sortArr.indexOf(a) - sortArr.indexOf(b);
        });
      }

      this.yArrayData = d3.stack()
        .keys(this.yKeys)
        .order(this.data.stackOrder)
        .offset(d3.stackOffsetNone)(this.visibleData.map(function(el) {
          var obj = el.y;
          obj.date = el.x;
          return obj;
        }));

      this.xAxisValues = this.xArrayData.reduce(function(acc, el) {
        var date = new Date(el);
        var day = date.getUTCDay();

        if (day === 1) {
          acc.push(self.toDateFormat(date));
        }

        return acc;
      }, []);

      var maxY = 100;
      var stepY = 25;
      this.yAxisValues = d3.range(0, maxY + stepY, stepY);
    },

    refreshHolderSize: function() {
      this.width = this.svgHolder.outerWidth();
      this.height = this.svgHolder.outerHeight() - this.marginBottom;
      this.bottomBound = this.height - this.lineHeight - this.sepHeight - this.sepMargin;
      this.barsFullWidth = this.width - this.leftPadding - (this.barsContainerPadding * 2);
    },

    toDateFormat: function(date) {
      return this.dateFormat(date);
    },

    setActiveData: function(key) {
      if (this.isInited) {
        this.destroySvg();
      }

      if (typeof key === 'undefined') {
        key = this.filter[0].dataset.value;
      }

      this.data = this.options.data[key];
      this.data.data = completeMissingData(this.data.data, 'object');
      this.setDataPart(this.visibleDataStartIndex);
      this.getAxisValues();
      this.scaleData();
      this.buildGraph();
      this.setPositionParams();
      this.buildLabels(key);
    },

    numIndex: function(dx) {
      var from = 0;
      var to = this.data.data.length - this.options.barsCount;

      this.prevIndex = this.visibleDataStartIndex;

      if (this.visibleDataStartIndex >= from && this.visibleDataStartIndex <= to) {
        this.visibleDataStartIndex += dx;
      }

      if (this.visibleDataStartIndex < from) {
        this.visibleDataStartIndex = 0;
      }

      if (this.visibleDataStartIndex > to) {
        this.visibleDataStartIndex = to;
      }

      return this.prevIndex !== this.visibleDataStartIndex;
    },

    destroySvg: function() {
      this.svgHolder.html('');
      this.labelTitle.html('');
      this.labelList.html('');

      this.data = {};

      this.visibleData = [];
      this.xArrayData = [];
      this.yArrayData = [];
      this.xAxisValues = [];
      this.yAxisValues = [];
      this.yKeys = [];
      this.xScaled = function() {};
      this.yScaled = function() {};
    },

    destroy: function() {
      var self = this;
      this.win.off('resize orientationchange', this.resizeHandler);

      d3.select(this.svgHolder[0]).on('.drag', null);

      clearTimeout(this.resizeTimer);
      clearTimeout(this.throttleTimer);

      this.svgHolder.off('touchstart mouseover', this.overFn);
      this.svgHolder.off('touchend mouseout', this.outFn);
      this.svgHolder.removeClass('dragging');
      this.svgHolder.removeClass('no-dragging');

      this.destroySvg();

      this.holder.removeData('graph_api_StackedHistogram');

      var keys = Object.keys(this);
      keys.forEach(function(key) {
        var type = typeof self[key];
        if (self.hasOwnProperty(key)) {
          self[key] = (type === 'function' || type === 'object' || type === 'array') ?
            null : undefined;
        }
      });
    },

    throttle: function (func, timeout) {
      var self = this;
      var execute = true;
      return function () {
        var args = [].slice.call(arguments);
        if (!execute) {
          return;
        }
        execute= false;
        func.apply(self, args);
        self.throttleTimer = setTimeout(function() {
          execute = true;
        }, timeout);
      };
    }
  };

  // jQuery plugin interface
  $.fn.stackedHistogram = function(opt) {
    var args = Array.prototype.slice.call(arguments);
    var method = args[0];

    return this.each(function() {
      var $holder = jQuery(this);
      var instance = $holder.data('graph_api_StackedHistogram');

      if (typeof opt === 'object' || typeof opt === 'undefined') {
        $holder.data('graph_api_StackedHistogram', new StackedHistogram($.extend({
          holder: this
        }, opt)));
      } else if (typeof method === 'string' && instance) {
        if (typeof instance[method] === 'function') {
          args.shift();
          instance[method].apply(instance, args);
        }
      }
    });
  };
}(jQuery));
