App.dateUtil || (App.dateUtil = {
  localePrefersMeridiem: function() {
    if (moment == null) { return true; }
    return moment.localeData().longDateFormat('LTS').match(/A/) != null;
  },
  dtFieldEach: function(id, func) {
    $(`input[data-field-id=${id}]`).each(function() {
      const fp = this._flatpickr;
      func(fp);
    });
  },
  setDtFieldNow: function(id) {
    this.dtFieldEach(id, fp => fp.setDate(new Date(), true));
  },
  setDtFieldSOD: function(id) {
    this.dtFieldEach(id, fp => {
      let x = fp.selectedDates;
      if (x.length === 0) {
        x = [new Date()];
      }
      x = x.map(dt => (new Date(dt.getTime()).setHours(0,0,0,0)));
      fp.setDate(x, true);
    });
  },
  setDtFieldEOD: function(id) {
    this.dtFieldEach(id, fp => {
      let x = fp.selectedDates;
      if (x.length === 0) {
        x = [new Date()];
      }
      // Note: Flatpickr does not support milliseconds
      x = x.map(dt => (new Date(dt.getTime()).setHours(23,59,59)));
      fp.setDate(x, true);
    });
  },
  setDtFieldEmpty: function(id) {
    this.dtFieldEach(id, fp => fp.setDate(null, true));
  },
});

$(document).on('turbolinks:load', function() {
  function formattedDate(date, fmt) {
    switch (fmt) {
      case 'ago': {
        return date.fromNow().replace(/^./, c => c.toUpperCase());
      }
      case 'words_local': {
        const x = moment(date).tz(moment.tz.guess());
        return `${x.format('LLLL')} (${x.zoneAbbr()})`;
      }
      case 'words_zoned': {
        const x = moment(date).parseZone();
        let words = x.format('LLLL');
        const offset = x.utcOffset();
        const tz_sign = offset >= 0 ? '+' : '-';
        const tz_hours = ((Math.abs(offset) / 60) | 0).toString().padStart(2, '0');
        const tz_minutes = ((Math.abs(offset) % 60) | 0).toString().padStart(2, '0');
        return `${words} (UTC${tz_sign}${tz_hours}:${tz_minutes})`;
      }
      default:
        return '';
    }
  }
  function prettifyDate() {
    const $this = $(this);
    const dateStr = $this.data('date');
    const textFmt = $this.data('text-fmt');
    const titleFmt = $this.data('title-fmt');

    const date = moment(dateStr);
    const text = formattedDate(date, textFmt);
    const title = formattedDate(date, titleFmt);

    $this.text(text);
    $this.attr('title', title).tooltipMobileSafe();
  }

  $('.date-iso8601').tlOnce().each(prettifyDate);

  function refreshDates() {
    const msUntilNextMinute = 60000 - (new Date()).getTime() % 60000;
    setTimeout(function() {
      $('.date-iso8601[data-auto-refresh-date="true"]').each(prettifyDate);
      refreshDates();
    }, msUntilNextMinute);
  };
  refreshDates();
});
