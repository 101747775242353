import React from "react";
import PropTypes from "prop-types";

class DishFoodMediaSegmentor_SingleSegmentFields extends React.Component {
  constructor(props) {
    super(props);
    const { segment, dishFood } = props;
    this.state = {}
  }

  componentDidMount() {
    $(this.refs.self).makeInputNumbersLocaleSafe();
    $([this.refs.ttspan_redraw, this.refs.ttspan_delete]).tooltip();
  }

  componentDidUpdate(prevProps, prevState) {
  }

  render() {
    const {
      dishFood,
      dishFoodIdx,
      segment,
      segmentIdx,
    } = this.props;
    const fieldName = (name) => (
      `ddsg[dish_foods_attributes][${dishFoodIdx}][segments_attributes][${segmentIdx}][${name}]`
    );
    const displayProps = {};
    if (this.props.selectedSegmentIdx !== segmentIdx) {
      displayProps.style = { display: 'none' }
    }
    return (
      <div ref="self" {...displayProps}>
        <input
          type="hidden"
          name={fieldName('id')}
          value={segment.id}
        />
        <input
          type="hidden"
          name={fieldName('media_id')}
          value={segment.mediaId}
        />
        <input
          type="hidden"
          name={fieldName('location_json')}
          value={JSON.stringify(segment.location)}
        />
        <input
          type="hidden"
          name={fieldName('ml_correct')}
          value={segment.mlCorrect == null ? '' : segment.mlCorrect ? '1' : '0'}
        />
        <hr className="mt-0"/>
        <div className="segment-title">
          <div>
            <div className="dot bg-primary"></div>
            <span>{`Dish Food ${(dishFoodIdx + 1).toString().padStart(2, '0')} Segment ${(segmentIdx + 1).toString().padStart(2, '0')}`}</span>
          </div>
          <div>
            <span
              className="mr-2"
              ref="ttspan_redraw"
              title="Change Segment Shape"
              style={{cursor: 'pointer'}}
              onClick={()=>this.props.onSegmentRedraw(dishFoodIdx, segmentIdx)}
            >
              <img src={RubyVars.iconUrls.draw} />
            </span>
            <span
              ref="ttspan_delete"
              title="Delete Segment"
              style={{cursor: 'pointer'}}
              onClick={(evt)=>{$(this.refs.ttspan_delete).tooltip('hide'); this.props.onSegmentDeleted(dishFoodIdx, segmentIdx);}}
            >
              <img src={RubyVars.iconUrls.delete} />
            </span>
          </div>
        </div>
      </div>
    );
  }
}

DishFoodMediaSegmentor_SingleSegmentFields.propTypes = {
  dishFood: PropTypes.object,
  dishFoodIdx: PropTypes.number,
  segmentIdx: PropTypes.number,
  selectedSegmentIdx: PropTypes.number,
  segment: PropTypes.object,
  selectedMediaId: PropTypes.number,
  onSegmentRedraw: PropTypes.func,
  onSegmentDeleted: PropTypes.func,
};

export default DishFoodMediaSegmentor_SingleSegmentFields;
