window.Utility = window.Utility || {};

Utility.Foods = {
  setSearch() {
    // Deleting a select2 tag will remove the tag's text from the search bar.
    // Must be done before $.select2 is called
    // Source: https://github.com/select2/select2/issues/3354#issuecomment-162858442
    $.fn.select2.amd.require(['select2/selection/search'], function (Search) {
      Search.prototype.searchRemoveChoice = function (_, item) {
        this.trigger('unselect', {
          data: item
        });
        this.$search.val('');
        this.handleSearch();
      };
    }, null, true);
  },

  setSelect2s() {
    // url for ajax request is set by the data-ajax--url attribute
    // in the select element
    $('select.foods_select2').tlOnce().select2({
      ajax: {
        delay: 250,
        data: (params) => ({
          filter: { 'display_name': params.term },
          sort_by: 'display_name',
          page: params.page || 1,
          format: 'json'
        }),
        cache: true
      },
      width: 'style',
      templateSelection: this.formatFood,
    });

    $('select.food_sets_select2').tlOnce().select2({
      ajax: {
        delay: 250,
        data: (params) => ({
          filter: { 'name': params.term },
          sort_by: 'name',
          page: params.page || 1,
          format: 'json'
        }),
        cache: true
      },
      width: 'style'
    });
  },

  handleSubmit() {
    $('.foods form.edit_food').tlOnce().submit(function (evt) {
      const $form = $(evt.target);
      const sections = [
        {
          name: 'children',
          containerClass: 'select-child-ids'
        },
        {
          name: 'parents',
          containerClass: 'select-parent-ids'
        }
      ];
      let has_removed_foods = false;

      sections.forEach(section => {
        const selectContainerClass = section.containerClass;
        const $select = $form.find(`.${selectContainerClass} select`);

        if ($select.length === 0) { return; }

        const original_ids = $select.data('original');
        if (original_ids) {
          const selected_original_ids = $form
            .find(`.${selectContainerClass} .select2-selection__choice>.food-item`)
            .toArray()
            .filter(item => original_ids.includes(+item.dataset['id']))
            .map(x => +x.dataset['id']);
          const removed_original_ids = original_ids.filter(
            x => !selected_original_ids.includes(x)
          );
          if (removed_original_ids.length === 0) {
            section['removed_foods'] = [];
            return; // continue to next section
          }
          const removed_section_foods = removed_original_ids.map(
            x => $select.find(`option[value=${x}]`).text()
          );
          section['removed_foods'] = removed_section_foods;
          has_removed_foods = true;
        }
      });
      if (!has_removed_foods) {
        return; // nothing removed, no need for warning
      }
      let message = 'Are you sure? This will REMOVE the following relationships:';
      for (const section of sections) {
        const removed_foods = section.removed_foods;
        if (!removed_foods.length) {
          continue;
        }
        message += `\n\nFrom "${section.name}":\n  `
        message += removed_foods.join('\n  ');
      }
      if (!confirm(message)) {
        evt.preventDefault();
        evt.stopPropagation();
      }
    });
  },

  handleKeyUp() {
    $('#food_bing_search_terms').tlOnce().keyup(function () {
      let $text_field = $(this);
      let prev = $text_field.data('previous-value');
      let curr = $text_field.val();
      if (prev !== curr) {
        $text_field.data('previous-value', curr);
        $('.dynamic-try-bing-images-link').each(function () {
          let $link = $(this);
          let name_en = $link.data('name-en').replace(/&quot;/g, '"');
          let terms = curr;
          let query = terms || name_en;
          let href = `https://www.bing.com/images/search?q=${encodeURIComponent(query)}`;
          $link.attr('href', href);
        });
      }
    });
  },

  formatFood(food) {
    let klass = 'food-item';
    klass += !!food.element.dataset['original'] ? ' original' : ' edited';
    let extra = food.element.dataset['class'];
    if (extra != undefined) {
      klass += ` ${extra}`
    }
    return $(`<span class="${klass}" data-id="${food.id}">${food.text}</span>`);
  },

  set() {
    this.setSearch();
    this.setSelect2s();
    this.handleSubmit();
    this.handleKeyUp();
  }
}

$(document).on('turbolinks:load', function() {
  Utility.Foods.set();
});
