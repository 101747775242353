import React from "react";

export default (props) => {
  const enabled = props.mode === 'draw_shape';
  const callback = (evt) => {
    if (!enabled) {
      return;
    }
    const $el = $(evt.target);
    $el.blur();
    props.onDrawButtonClick($el.data('action'));
  }
  const style = {};
  if (!enabled) {
    style.opacity = 0;
    style.pointerEvents = 'none';
  }
  return (
    <div className="d-flex mb-4" style={style}>
      <button
        type="button"
        className="btn btn-secondary mr-2"
        data-action="undo"
        onClick={callback}
      >
        <img src={RubyVars.iconUndoUrl} className="btn-icon" />
        Undo
      </button>
      <button
        type="button"
        className="btn btn-secondary mr-2"
        data-action="close-and-continue"
        onClick={callback}
      >
        <img src={RubyVars.iconDrawUrl} className="btn-icon" />
        Close Polygon and Continue Drawing
      </button>
      <button
        type="button"
        className="btn btn-secondary mr-2"
        data-action="close-and-finalize"
        onClick={callback}
      >
        <img src={RubyVars.iconCloseUrl} className="btn-icon" />
        Close Polygon and Finalize Shape
      </button>
      <button
        type="button"
        className="btn btn-secondary mr-2"
        data-action="cancel"
        onClick={callback}
      >
        <i className="fas fa-times color-notice-error"></i> Cancel
      </button>
    </div>
  );
};
