// If a JS error occurs in the browser, the software can be left in
// a bad state depending on how badly the JS console decides to crash.
// Subsequent errors can continue to be triggered because the page is
// never reloaded - so force a full page reload on the next page visit.
// Source: https://github.com/turbolinks/turbolinks/issues/277#issuecomment-317424899

window.TurbolinksAppErrorListener = window.TurbolinksAppErrorListener || {
  applied: false,
  errors: [],
};

if (!TurbolinksAppErrorListener.applied) {
  TurbolinksAppErrorListener.applied = true;

  window.addEventListener('error', function() {
    TurbolinksAppErrorListener.errors.push('client');
  });

  document.addEventListener("turbolinks:before-visit", function(evt) {
    if (TurbolinksAppErrorListener.errors.length > 0) {
      evt.preventDefault(); // Cancel the turbolinks request
      window.location.href = evt.data.url; // Do a regular page visit to clear the JS console
    }
  });
}
