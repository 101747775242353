// TurboLinks idempotent processing
$.fn.tlOnce = function() {
  let id = App.Util.fnCallerId();
  let key = `data-tlonce-${id}`;
  return this.filter(function() {
    let $el = $(this);
    let ok = !($el.attr(key) === 'processed');
    if (ok) {
      $el.attr(key, 'processed');
    }
    return ok;
  });
}
