$.fn.makeInputNumbersLocaleSafe = function() {
  const clampStr = (val, min, max) => {
    if (val.length === 0) {
      return val;
    }
    const nVal = +val;
    const nMin = +min;
    const nMax = +max;
    if (min != null && nVal < nMin) {
      return min;
    }
    if (max != null && nVal > nMax) {
      return max;
    }
    return val;
  };

  const inputFunc = function(evt) {
    const $field = $(this);
    const oldStart = this.selectionStart;
    const oldEnd = this.selectionEnd;
    const oldVal = $field.val();
    if (oldVal === '') {
      $field.val('');
      return;
    }
    let newVal = oldVal
      .replace(/,/g, '.') // force ',' to '.'
      .replace(/[\-]/g, '-') // force various minus signs to '-'
      .replace(/[^0-9.-]+/g, '') // only allow numbers and '.' and '-'
      .replace(/^(-?)0*(0\.|[0-9])/, '$1$2'); // limit leading '0's
    newVal = newVal.slice(0, 1) + newVal.slice(1).replace(/\-+/g, ''); // remove all but the first '-'
    const splitIdx = newVal.indexOf('.') + 1
    if (splitIdx !== 0) { // only allow a max of 1 point
      newVal = newVal.slice(0, splitIdx) + newVal.slice(splitIdx).replace(/\./g, '');
    }
    const preClampVal = newVal;
    newVal = clampStr(newVal, $field.data('min'), $field.data('max'));
    if (oldVal !== newVal) {
      $field.val(newVal);
      if (preClampVal === newVal) {
        // fix cursor positon
        let newStart = oldVal.slice(0, oldStart).replace(/[^0-9.,-]+/g, '').length;
        let newEnd = newStart + oldVal.slice(oldStart, oldEnd).replace(/[^0-9.,-]+/g, '').length;
        if (splitIdx !== 0 && oldStart !== oldVal.length) {
          if (newStart > splitIdx) {
            newStart -= 1;
          }
          if (newEnd > splitIdx) {
            newEnd -= 1;
          }
        }
        this.setSelectionRange(newStart, newEnd);
      }
    }
  };
  const keydownFunc = function(evt) {
    const incrementValue = (amt) => {
      const $el = $(this);
      const [oldValInteger, oldValDecimal] = $el.val().split('.');
      let newVal = ((+oldValInteger + amt) | 0).toString();
      if (oldValDecimal != null) {
        newVal += '.' + oldValDecimal;
      }
      $el.val(clampStr(newVal, $el.data('min'), $el.data('max')));
    }
    switch (evt.key) {
    case 'ArrowDown':
      incrementValue(-1);
      break;
    case 'ArrowUp':
      incrementValue(1);
      break;
    }
  };

  const $inputs = $(this).find('input.number-safe');
  $inputs.off('input');
  $inputs.on('input', inputFunc);
  $inputs.off('keydown');
  $inputs.on('keydown', keydownFunc);
}

$(document).on('turbolinks:load', function() {
  const $forms = $('form');
  $forms.find('.field_with_errors > .form-control').blur(function() {
    const $field = $(this);
    $field.attr('blurred', true);
    const id = $field.attr('id');
    $field.closest('form').find(`.form-field-error-list[data-field-id="${id}"]`).hide();
  });
  $forms.find('.prefill-link').click(function() {
    const $el = $(this);
    const $target = $('#' + $el.data('target'));
    if ($target.length === 0) {
      alert(`Cannot find element with id: "${$el.data('target')}"`)
      return;
    }
    let newVal = $el.data('value');
    const oldVal = $target.val();
    if (oldVal.length > 0) {
      const spaceIfNeeded = /\s/.test(oldVal[oldVal.length-1]) ? '' : ' '
      newVal = oldVal + spaceIfNeeded + newVal;
    }
    $target.val(newVal);
    $target.focus();
  });


  // restrain input on number fields that are semantically 'text'
  $forms.makeInputNumbersLocaleSafe();
});
