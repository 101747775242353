$.fn.tooltipMobileSafe = function() {
  const attr = 'touchCount';
  const getTouchCount = $el => +($el.attr(attr) || 0);
  const clearTouchCount = $el => $el.removeAttr(attr);
  const incrementTouchCount = $el => $el.attr(attr, getTouchCount($el) + 1);
  const resetTouchCount = $el => { if ($el.attr(attr)) { $el.attr(attr, 1); } };
  $(this)
    .filter((i, el) => el != null) // #.tooltip raises on undefined elements
    .tooltip()
    .on('show.bs.tooltip', function () {
      resetTouchCount($(this)); // Extra touches could come from scrolling
    })
    .on('hide.bs.tooltip', function () {
      clearTouchCount($(this));
    })
    .on('touchstart', function () {
      incrementTouchCount($(this));
    })
    .on('click', function(evt) {
      const $el = $(this);
      if ($el.data('original-title') && getTouchCount($el) === 1) {
        // in touch environment, require another tap
        // since the first tap is just for showing the tooltip
        evt.preventDefault();
        evt.stopPropagation();
      } else {
        // hide tooltip when clicking through, since a page change in turblinks
        // removes the element from the DOM and then the tooltip is stuck.
        $el.tooltip('hide');
      }
    });
}
