//helper functions
export function projection(meta) {
  var metaKeys = Object.keys(meta);

  return function(data) {
    var result = [];
    var i = 0;
    var len = data.length;

    while(i < len) {
      result.push({});
      metaKeys.forEach(function(key) {
        var metaValueList = meta[key];
        var propName = metaValueList[0];
        var valueFn = typeof metaValueList[1] === 'function' ?
          metaValueList[1] : id;

        result[result.length-1][propName] = valueFn(data[i][key]);
      });
      i++;
    }

    return result;
  };
}

function id(x) {
  return x;
}

export function sortByDate(first, second) {
  return new Date(first.x) - new Date(second.x);
}

export function getFiltered(arr, propName, val) {
  return arr.filter(function(el) {
    return el[propName] === val;
  });
}

export function convertToNumber(val) {
  var num = parseFloat(val);
  return isNaN(num) ? 0 : num;
}

export function convertToRoundedNumber(val) {
  const num = parseFloat(val.toFixed(2))
  return isNaN(num) ? 0 : num;
}

export function calculateDataToDaily(data, reducer, definer, isOneTimeFn, groupKey, dateKey) {
  var dateKey = typeof dateKey === 'undefined' ? 'date' : dateKey;
  return data.reduce(function(acc, cur) {
    var keys = Object.keys(cur);
    var last = acc.length ? acc[acc.length - 1] : false;

    if (last) {
      if (isOneTimeFn(last[dateKey], cur[dateKey])) {
        keys.forEach(function(el) {
          if (typeof reducer === 'function') {
            reducer(cur, acc, el, groupKey);
          }
        });
      } else {
        if (typeof definer === 'function') {
          definer(acc, cur, keys, groupKey);
        }
      }
    } else {
      if (typeof definer === 'function') {
        definer(acc, cur, keys, groupKey);
      }
    }

    return acc;
  }, []);
}

export function isOneDay(lastTime, curTime) {
  return new Date(lastTime).getUTCDate() === new Date(curTime).getUTCDate();
}

export function isOneHour(lastTime, curTime) {
  return new Date(lastTime).getUTCHours() === new Date(curTime).getUTCHours();
}

export function isOneMinute(lastTime, curTime) {
  return new Date(lastTime).getUTCMinutes() === new Date(curTime).getUTCMinutes();
}

export function toTimePeriodReducerFn(cur, acc, el, groupKey) {
  var dataList = acc[acc.length-1].data;

  if (el === groupKey) {
    dataList.push(cur);
  }
};

export function toTimePeriodDefinerFn(accObj, curObj, keys, groupKey) {
  var obj = {};

  obj[groupKey] = curObj[groupKey];
  obj.data = [curObj];

  accObj.push(obj);
};

export function completeMissingData(data, yDataType) {
  var missing_dates = [];
  var dates = data.map(function(obj) { return obj.x; });

  for (var i = 1; i < dates.length; i++ ) {
    var currentDate = new Date(dates[i]);
    var previousDate = new Date(dates[i - 1]);
    var timeDiff = currentDate.getTime() - previousDate.getTime();
    var dayDiff = timeDiff / (1000*60*60*24);

    if (dayDiff > 1 ) {
      for (var x = 1; x <= dayDiff; x++) {
        var dateTimeFormat = new Intl.DateTimeFormat(
          'en',
          { year: 'numeric', month: '2-digit', day: '2-digit' }
        );
        var [{ value: month },,{ value: day },,{ value: year }] = dateTimeFormat.formatToParts(previousDate.addDays(x));
        if(yDataType === 'object') {
          missing_dates.push({x: `${year}-${month}-${day}`, y: {}});
        } else if (yDataType === 'integer') {
          missing_dates.push({x: `${year}-${month}-${day}`, y: 0});
        }
      }
    }
  }
  return data.concat(missing_dates).sort(sortByDate);
}
